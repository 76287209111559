import React from 'react';
import TableList from "../../components/List/TableList";


const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '3%', show: true, sortable: true},
  {accessor: 'fullname', label: 'FullName', width: '14%', show: true,  type: 'fieldValue'},
  {accessor: 'phone', label: 'Phone', width: '10%', show: true, type: 'fieldValue'},
  {accessor: 'office.name', label: 'Office', width: '10%', show: true, type: 'fieldValue'},
  {accessor: 'num_check', label: 'Num Check', width: '10%', show: true,},
  {accessor: 'agency.fr_name', color: 'default', label: 'Agency', width: '10%', show: true, type: 'fieldValue'},
  {accessor: 'status',  name: 'status', color: 'default', label: 'Status', width: '6%', show: true, sortable: true,},
 {accessor: 'payment_agreement_slice.id', label: 'Slice Id', width: '6%', show: true, type: 'fieldValue'},
  {accessor: 'check_date', name: 'checkDate', label: 'Check Date', width: '13%', type : 'date', show: true, sortable: true,},
  {accessor: 'benif', label: 'Benif', width: '10%', show: true,},
  {accessor: 'admin_user.name', label: 'Admin', width: '13%', show: true},
];

const fieldSearchable = [
  'id',
  'numCheck',
];
const fieldFilterable = [
  {name: 'user.id', label: 'User', type: 'userAutocomplete'},
  {name: 'status', label: 'Status', type: 'dropdown', options: ['used', 'not used'],},
  {name: 'office.id', label: 'Office', type: 'officeAutocomplete'},
  {name: 'agency.id', label: 'Agency', type: 'agencyAutocomplete'},
  {name: 'CheckDate', label: 'Check Date', type: 'dateRange'},
  {name: 'adminUser.id', label: 'Admin', type: 'adminAutocomplete'},

];
const CheckList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`checks`}
          exportUrl={`checks/export`}
          pageLink ={'/finance/checks'}
          searchMessage={'Search (ID, NumCheck)'}
          baseUrl={`checks`}
          noDelete={true}
          noDeleteMany={true}
          removeAdd={true}
      />
  );
};

export default CheckList;
