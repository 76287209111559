import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '10%', show: true},
  {accessor: 'penalty_detail.name', label: 'Penalty Detail', show: true},
  {accessor: 'event.id', label: 'Event', show: true},
  {accessor: 'instructor', label: 'Instructor', show: true, type:'concatString'},
  {accessor: 'confirmed', label: 'Confirmed', type: 'boolean', color: 'primary', show: true},
  {accessor: 'canceled', label: 'Canceled', type: 'boolean', color: 'primary', show: true},
];

const fieldSearchable = [
  'id',
];

const fieldFilterable = [
  {name: 'id', label: 'ID', type: 'text'},
  {name: 'instructor.id', label: 'Instructor', type: 'text'},
  {name: 'event.id', label: 'Event', type: 'text'},
  {name: 'penalty_detail', label: 'Penalty Detail', type: 'text'},
];

const EventPenaltyList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`event-penalties`}
          exportUrl={`penalties/export`}
          pageLink ={'/teacher-settings/event-penalties'}
          baseUrl={`event-penalties`}
          deleteUrl={`delete/many-event-penalties`}
          keyValue={"eventPenalties"}
          noShow={true}
          removeAdd={true}
          noEdit={true}          
      />
  );
};

export default EventPenaltyList;
