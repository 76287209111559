import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  months,
  initialNumbersCurrentMonth,
} from "../utils/constants";
import api from "services/api";
import { convertMonth } from "helpers/methods";

const initialState = {
  yearIncomes: {
    numbersCurrentMonth: initialNumbersCurrentMonth,
    data: [],
    status: "idle",
    error: null,
  },
  currentDataDate: {
    month: 1,
    year: new Date().getFullYear(),
  },
  monthIncomes: {
    MonthData: [],
    status: "idle",
    error: null,
  },
  monthDetailsDirect: {
    data: [],
    status: "idle",
    error: null,
  },
  MonthDetailsDefaults: {
    title: "",
    data: [],
    status: "idle",
    error: null,
  },
};

export const fetchIncomes = createAsyncThunk(
  "chapters/fetchIncomes",
  async (query) => {
    const { instructor, year } = query;
    let data;

    try {
      const response = await api.get(
        `/incomes/instructor/${instructor}/year/${year}`
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchMonthIncomes = createAsyncThunk(
  "incomes/fetchMonthIncomes",
  async (params) => {
    let data;
    try {
      const response = await api.get(
        `/incomes/instructor/${params.instructor}/month/${params.month}/year/${params.year}`
      );

      data = await response.data;
      if (response.status === 200) {
        return { data, params };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchMonthDetailsDirect = createAsyncThunk(
  "incomes/fetchMonthDetailsDirect",
  async (params) => {
    let data;
    try {
      const response = await api.get(
        `/incomes/instructor/${params.instructor}/live-sessions/month/${params.month}/year/${params.year}`
      );

      data = await response.data;
      if (response.status === 200) {
        return { data, params };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchMonthDetailsDefaults = createAsyncThunk(
  "incomes/fetchMonthDetailsDefaults",
  async ({ event, instructor, title }) => {
    let data;
    try {
      const response = await api.get(
        `/incomes/instructor/${instructor}/penalties/event/${event}`
      );

      data = await response.data;
      if (response.status === 200) {
        return { data, title };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "incomes",
  initialState,
  reducers: {
    changeYear: (state, action) => {
      state.currentDataDate.year = action.payload;
    },
  },
  extraReducers: {
    [fetchIncomes.pending]: (state) => {
      state.yearIncomes.status = "loading";
    },
    [fetchIncomes.fulfilled]: (state, action) => {
      state.yearIncomes.status = "succeeded";

      const numbersCurrentMonthAPI = action.payload.data.numbersCurrentMonth;
      const dataAPI = action.payload.data?.incomes || null;
      let data = null;
      const numbersCurrentMonth = JSON.parse(
        JSON.stringify(initialNumbersCurrentMonth)
      );

      if (numbersCurrentMonthAPI) {
        const keys = Object.keys(numbersCurrentMonthAPI);
        keys.forEach((key1, index) => {
          const nestedKeys = Object.keys(numbersCurrentMonthAPI[key1]);
          const value = nestedKeys.reduce(
            (prev, key2) => numbersCurrentMonthAPI[key1][key2],
            0
          );
          numbersCurrentMonth[index] = {
            ...numbersCurrentMonth[index],
            value,
            id: key1,
          };
        });
      }

      if (dataAPI) {
        data = months.map((month, index) => ({
          id: months[index].id,
          month: months[index].name,
          balance: dataAPI[months[index].id].solde,
          fault: dataAPI[months[index].id].default,
          finalBlance: dataAPI[months[index].id].finalSolde,
        }));
      }

      state.yearIncomes.data = data;
      state.yearIncomes.numbersCurrentMonth = numbersCurrentMonth;
    },
    [fetchIncomes.rejected]: (state, action) => {
      state.yearIncomes.status = "failed";
      state.yearIncomes.error = action.payload;
    },
    [fetchMonthIncomes.pending]: (state) => {
      state.monthIncomes.status = "loading";
    },
    [fetchMonthIncomes.fulfilled]: (state, action) => {
      state.monthIncomes.status = "succeeded";
      const dataAPI = action.payload.data.data.incomes;
      const monthNumber = convertMonth(action.payload.params.month);
      const data = {
        info: {
          month: months[monthNumber].name,
          year: action.payload.params.year,
          totalBalance: action.payload.data.data.total,
        },
      };
      const keys = [
        { id: "liveSessions", articleName: "Séan. en direct", unit: null },
        { id: "videoSilver", articleName: "vidéos silver", unit: "/mn" },
        { id: "videoFree", articleName: "vidéos gratuites", unit: "/mn" },
        { id: "magazine", articleName: "Magazines", unit: null },
        { id: "correctedExam", articleName: "Correct. examen", unit: null },
        { id: "qcm", articleName: "Qcm", unit: null },
      ];
      keys.forEach((key1, index) => {
        const nestedKeys = Object.keys(dataAPI[key1.id]);

        if (nestedKeys.length > 0) {
          const unitPrices = nestedKeys.map((key) => ({
            title: key,
            unitPrice: dataAPI[key1.id][key].unitPrice,
          }));
          const value = nestedKeys.reduce(
            (prev, key2) => ({
              totalPrice:
                prev.totalPrice +
                dataAPI[key1.id][key2].quantity *
                  dataAPI[key1.id][key2].unitPrice,
              quantity: prev.quantity + dataAPI[key1.id][key2].quantity,
            }),
            { quantity: 0, totalPrice: 0 }
          );
          keys[index].quantity = value.quantity;
          keys[index].totalPrice = value.totalPrice;
          keys[index].unitPrices = unitPrices;
        } else {
          keys[index].quantity = 0;
          keys[index].totalPrice = 0;
          keys[index].unitPrices = [];
        }
      });
      data.data = keys;
      const defaults = Object.keys(dataAPI.liveSessions).reduce(
        (prev, key) =>
          dataAPI.liveSessions[key].default &&
          dataAPI.liveSessions[key].default + prev,
        0
      );
      data.data[0].default = defaults;
      state.monthIncomes.MonthData = data;
      state.currentDataDate = { ...action.payload.params };
    },
    [fetchMonthIncomes.rejected]: (state, action) => {
      state.monthIncomes.status = "failed";
      state.monthIncomes.error = action.payload;
    },
    [fetchMonthDetailsDirect.pending]: (state) => {
      state.monthDetailsDirect.status = "loading";
    },
    [fetchMonthDetailsDirect.fulfilled]: (state, action) => {
      state.monthDetailsDirect.status = "succeeded";
      state.monthDetailsDirect.data = action.payload.data;
    },
    [fetchMonthDetailsDirect.rejected]: (state, action) => {
      state.monthDetailsDirect.status = "failed";
      state.monthDetailsDirect.error = action.error.message;
    },
    [fetchMonthDetailsDefaults.pending]: (state) => {
      state.MonthDetailsDefaults.status = "loading";
    },
    [fetchMonthDetailsDefaults.fulfilled]: (state, action) => {
      state.MonthDetailsDefaults.status = "succeeded";
      const { data } = action.payload.data;
      state.MonthDetailsDefaults.data = data;
      state.MonthDetailsDefaults.title = action.payload.title;

    },
    [fetchMonthDetailsDefaults.rejected]: (state, action) => {
      state.MonthDetailsDefaults.status = "failed";
      state.MonthDetailsDefaults.error = action.error.message;
    },
  },
});

export const reducer = slice.reducer;
export const { changeYear } = slice.actions;

export default slice;
