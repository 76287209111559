import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Toolbar from '../../components/Toolbar/Toolbar'
import Table from '../../components/Table/Table';
import Collapse from '@material-ui/core/Collapse';
import Columns from '../../components/Columns';
import Filter from '../../components/Filter/Filter';
import optionsParser from '../../helpers/optionsParser';
import Loading from '../../components/Loading/Loading';
import {api_delete, api_get, get_file} from "../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', show: true, sortable: true},
  {accessor: 'content.name', label: 'Title', show: true},
  {accessor: 'subject_events', label: 'Subject', show: false, type:'concatSubject'},
  {accessor: 'content.active', type: 'boolean', color: 'primary', label: 'Active', show: true,},
  {accessor: 'content.is_draft', type: 'boolean', color: 'primary', label: 'Draft', show: true,},
  {accessor: 'canceled', type: 'boolean', color: 'primary', label: 'Canceled', show: true, width: '5%'},
  {accessor: 'url', label: 'Url', show: false},
  {accessor: 'webinar_account.email', label: 'Webinar', show: true},
  {accessor: 'start_date', name: 'startDate',  label: 'Start Date', type: 'dateTime', show: true, sortable: true},
  {accessor: 'end_date', name: 'endDate',  label: 'End Date', type: 'dateTime', show: false, sortable: true},
  {accessor: 'content.instructor', label: 'Professor', show: true, type: 'instructorSwitch'},
  {accessor: 'traineeship', label: 'Traineeship', show: true, type: 'traineeshipLink'},
  {accessor: 'traineeship.traineeship_levels', label: 'Levels', show: false, type: 'levels'},
  {accessor: 'content.creator_admin.username', label: 'Admin', show: true, width: '10%', type: 'fieldValue'},
  {accessor: 'content.created_at', label: 'created at', type: 'date' ,show: false},
];

const fieldSearchable = [
  'id',
  //'content.name',
];
const fieldFilterable = [
  {name: 'content.name', label: 'Name', type: 'text'},
  {name: 'url', label: 'Url', type: 'text'},
  {name: 'webinarAccount', label: 'Webinar account', type: 'webinarAccountAutocomplete',},
  {name: 'content.instructor.id', label: 'Instructor', type: 'instructorAutocomplete',},
  {name: 'subjectEvents.subject.division.id', label: 'Division', type: 'divisionAutocomplete',},
  {name: 'subjectEvents.subject.id', label: 'Subject', type: 'subjectAutocomplete',},
  {name: 'startDate', type: 'dateRange', label: 'Start Date'},
  //{name: 'startDate', type: 'date', label: 'Start Date'},
  //{name: 'endDate', type: 'date', label: 'End Date'},
  {name: 'traineeship.id', label: 'Hessaty Traineeships', type: 'traineeshipAutocompleteFilter'},
  {name: 'content.creatorAdmin.id', label: 'Admin', type: 'adminAutocomplete'},
  // {name: 'contents.visible', label: 'Visible', type: 'boolean'},

];
const TraineeshipEventList = () => {
  const [search, searchChange] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [filters, filtersChange] = useState({});
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({accessor: 'id', order: 'desc'});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(24);

  useEffect(() => {
    getData();
  }, [page, sort, rowsPerPage]);

  useEffect(() => {
    if (!search && isSearch) {
      setIsSearch(false)
      setPage(1);
      getData();
    }
  }, [search]);

  useEffect(() => {
    const options = optionsParser(search, filters, sort, fieldSearchable).replace( '&search=', ';' );
    if (search.length ) {
      setIsSearch(true)
      setPage(1);
      api_get(`events?page=${page}&search=traineeship:isnotnull${options}&searchJoin=and`).then((data) => {
        setData(data);
      });
    }
    if(Object.keys(filters).length){
      setPage(1);
      api_get(`events?page=${page}&search=traineeship:isnotnull${options}&searchJoin=and`).then((data) => {
        setData(data);
      });
    }
  }, [filters, search]);

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const getData = () => {
    const options = optionsParser(search, filters, sort, fieldSearchable).replace( '&search=', ';' );
    api_get(`events?page=${page}&search=traineeship:isnotnull${options}&perPage=${rowsPerPage}`).then((data) => {
      setData(data);
    });
  };

  const getListData = () => {
    const options = optionsParser(null, null, sort, null);
    api_get(`events?search=traineeship:isnotnull&page=${page}${options}&perPage=${rowsPerPage}`).then((data) => {
      setData(data);
    });
  };
  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    //getData(event.target.value)
  };
  const handleExport = () => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    get_file(`events/export?searchJoin=and&page=${page}${options}`)
  }
  const classes = useStyles();
  if (!data.payload) {
    return (<Loading/>);
  }
  return (
      <div className={classes.root}>
        <Toolbar toggleFilters={toggleFilters} toggleColumns={toggleColumns}
                 searchChange={searchChange} pageLink='/hessaty/content-events' 
                 title={'Hessaty Events'} 
                 searchMessage={'Search (ID)'}
                 handleExport={handleExport}
        />
        <div className={classes.content}>
          <Collapse in={columnsOpen}>
            <Columns columnsChange={columnsChange} columns={columns}/>
          </Collapse>
          <Collapse in={filtersOpen}>
            <Filter fields={fieldFilterable} values={filters}
                    filtersChange={filtersChange} initializeData={getListData}/>
          </Collapse>
          <Table columns={columns} data={data}
                 handleSortChange={handleSortChange} sort={sort}
                 handlePageChange={handlePageChange} page={page}
                 tableService={{method: api_delete, base_url: `events`}} 
                 pageLink='/hessaty/content-events'
                 rowsPerPage={rowsPerPage}
                 handleRowsPerPageChange={handleRowsPerPageChange}
                 initializeData={getData}
                 replace ={true}
                 />
        </div>
      </div>
  );
};

export default TraineeshipEventList;
