import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '3%', show: true, sortable: true},
  {accessor: 'user',label: 'User',width: '14%',show: true,type: 'concatString',},
  {accessor: 'account.name', label: 'Account', width: '10%', show: true},
  {accessor: 'office.name', label: 'Office', width: '10%', show: true},
  {accessor: 'reference',name: 'reference',label: 'Reference',width: '10%',show: true,sortable: true,},
  {accessor: 'amount',name: 'amount',color: 'default',label: 'Amount',width: '5%',show: true,sortable: true,},
  {accessor: 'type',name: 'type',color: 'default',label: 'Payment Type',width: '6%',show: true,sortable: true,},
  {accessor: 'status',name: 'status',label: 'Status',width: '6%',show: true,sortable: true,},
  {accessor: 'created_at',name: 'createdAt',label: 'Date',width: '13%',type : 'date',show: true,sortable: true,},
  {accessor: 'payment_method.description',label: 'Payment Method',width: '10%',show: true,},
  {accessor: 'admin_user.name', label: 'Admin', width: '13%', show: true},
  {accessor: 'comment', label: 'Comment', width: '20%', show: true},
];

const fieldSearchable = [
  'id',
  'reference',
];
const fieldFilterable = [
  {name: 'user.id', label: 'User', type: 'userAutocomplete'},
  {name: 'amount', label: 'Amount', type: 'number'},
  {name: 'status', label: 'Status', type: 'dropdown', options: ['ignored', 'approuved', 'pending', 'active'],},
  {name: 'type', label: 'Type', type: 'dropdown', options: ['cash', 'check', 'transfer', 'payment', 'poste'],},
  {name: 'paymentMethod.id', label: 'Payment method', type: 'paymentMethodAutocomplete',},
  {name: 'office.id', label: 'Office', type: 'officeAutocomplete'},
  {name: 'account.id', label: 'Account', type: 'accountAutocomplete'},
  {name: 'agency2.id', label: 'Agency', type: 'agencyAutocomplete'},
  {name: 'cashedAt', label: 'Cashed at', type: 'dateRange'},
];
const CodeArchiveList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`codes/archived`}
          exportUrl={`codes/archived/export`}
          pageLink ={'/archives/codes'}
          searchMessage={'Search (ID, Reference)'}
          baseUrl={`codes/archived`}
          removeAdd={true}
          restore={true}
          noShow={true} noDelete={true} noEdit = {true} noDeleteMany={true}
      />
  );
};

export default CodeArchiveList;
