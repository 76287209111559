// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Button, Card, CardContent, CardHeader, Divider, Grid, TextField, Box, Typography,label,  List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import PerfectScrollbar from "react-perfect-scrollbar";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import {api_get,  api_put, api_post_serialize, api_post} from "../../../utils/Api";
import {getUsers, getParents} from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    'overflow': 'visible',
  },
  files:{
    marginTop: theme.spacing(4),
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
  content: {
    padding: 0,
  },
  message: {
    padding: theme.spacing(1),
  }
}));

type Props = {
  edit: boolean,
  className: string,
  search: string,
}

const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, search, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState<values>({
    user: '',
    parentUser: '',
    approved: false,
  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [fileName, setName] = useState('');
  const [fileUrl, setUrl] = useState('');
  const [fileId, setID] = useState(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      setLoading(true)
      api_get(`parent-user-children/${id}`).then((result) => {
        setLoading(false)
        const data = result.payload;
        setFiles(data?.files)
        setID(data?.files.id)
        setName(data?.files.name)
        setUrl(data?.files.url)
        setValues({
          user:       data.parentUserChild.user,
          parentUser: data.parentUserChild.parent_user,
          approved:   data.parentUserChild.approved === true ? "1" : "0",
        });
      });
    }
  }, []);

    const handleChange = (event) => {
      setValues({...values,  [event.target.name]: event.target.value});
  };

  const parentChange = (value) => {
    setValues(
        {
          ...values,
          parentUser: value,
        });
  };
  const userChange = (value) => {
    setValues(
        {
          ...values,
          user: value,
        });
  };

  const info =  (id,name,lastname) => {
   return  `${'[' + id + ']'} + ' ' + ${name} + ' ' + ${lastname}`
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const showErrorMessage = (errors) => {
    Object.keys(errors).map((key, index) => {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const formValidation = (values) => {
    if (!values.user) {
      return { success: false, message: "Children is missing" };
    }
    if (!values.parentUser) {
      return { success: false, message: "Parent is missing" };
    }
  };
  const handleSubmit = () => {
    const requestValues = mapStateToRequest(values, [], []);
    setLoading(true);
    const validation = formValidation(requestValues);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity("warning");
      setOpen(true);
    } else {
      if (props.edit) {
        api_put(`parent-user-children/${id}`, requestValues).then((result) => {
          if (result?.errors) {
            showErrorMessage(result.errors);
          } else if (result?.code !== 200 && result?.code !== 201) {
            setAlertSeverity("warning");
            setAlertMessage(
              result?.message
                ? result.message
                : "Technical error! Contact the Developers Team"
            );
            setOpen(true);
          } else {
            setAlertSeverity("success");
            setAlertMessage("Updated successfully");
            setLoading(false);
            setOpen(true);
            window.location.href = '/users/parent-user-children';
          }
        });
      } else {
        api_post(`parent-user-children`, requestValues)
          .then((result) => {
            if (result?.errors) {
              showErrorMessage(result.errors);
            } else if (result?.code !== 200 && result?.code !== 201) {
              setAlertSeverity("warning");
              setAlertMessage(
                result?.message
                  ? result.message
                  : "Technical error! Contact the Developers Team"
              );
              window.location.href = '/users/parent-user-children';
              setOpen(true);
            } else {
              setAlertSeverity("success");
              setAlertMessage("Created successfully");
              setOpen(true);
              setLoading(false);
              window.location.href = '/users/parent-user-children';
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Card className={classes.root}>
              <CardContent>
              <Box mt={1}>
                <AsyncPaginate
                    loadOptions={getParents}
                    value={values.parentUser}
                    onChange={parentChange}
                    getOptionLabel={(option) => '[' + option.id + ']' + ' ' +
                        option.name + ' ' + option.last_name }
                    getOptionValue={(option) => option.id}
                    placeholder="Parent"
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: '53px',
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: '#000',
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector('MuiPaper-root')}
                />
                </Box>
                <Box mt={2}>
                  <AsyncPaginate
                      loadOptions={getUsers}
                      value={values.user}
                      onChange={userChange}
                      placeholder="Children"
                      getOptionLabel={(option) => '[' + option.id + ']' + ' ' +
                      option.name + ' ' + option.last_name }
                      getOptionValue={(option) => option.id}
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                  />
                </Box>
                <Box mt={1}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Verified</FormLabel>
                    <RadioGroup
                      aria-label="approved"
                      name="approved"
                      onChange={handleChange}
                      value={values.approved || ""}
                      row
                    >
                      <FormControlLabel
                        control={<Radio />}
                        value="1"
                        label="Yes"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value="0"
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  </Box>
              </CardContent>
             
            </Card>
            <Grid item xs={12} lg={12} spacing={3}>
              <Card className={classes.files}>
               {edit && 
                <CardContent>
                  <Box>
                    <Typography variant="subtitle1" color="textSecondary">
                      Documents
                    </Typography>
                    <Divider />
                  </Box>
                  {files && files.length > 0 ? (
                    <>
                      <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <List className={classes.list}>
                          {files.map((file, i) => (
                            <ListItem divider={i < files.length - 1} key={i}>
                              <ListItemLink href={file?.url}>
                              <ListItemText
                                primary={file.name}
                                style={{overflowWrap: 'break-word'}}
                                primaryTypographyProps={{ variant: "h6" }}
                              />
                              </ListItemLink>
                            </ListItem>
                          ))}
                        </List>
                        </PerfectScrollbar>
                    </>
                  ) : (
                    <Typography className={clsx(classes.message, className)} variant="body2" color="textSecondary"
                      component="p">
                        No files
                    </Typography>
                  )}
                </CardContent>}
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Save details
          </Button>
        </Box>
      </form>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Form;
