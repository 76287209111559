import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Grid, makeStyles} from '@material-ui/core';
import BasicsInfo from './BasicsInfo';
import SlicesInfo from './SlicesInfo';


const useStyles = makeStyles(() => ({
  root: {},
}));

const Details = ({
  affiliation,
  slices,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
      {...rest}
    >
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        <BasicsInfo affiliation={affiliation} />
      </Grid>
      <Grid
        item
        lg={8}
        md={8}
        xl={8}
        xs={12}
      >
        <SlicesInfo slices= {affiliation.payment_agreements[0]?.payment_agreement_slices} />
      </Grid>
    </Grid>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  affiliation: PropTypes.object.isRequired,
  slices: PropTypes.object.isRequired,
  paymentAgreements: PropTypes.object.isRequired,


};

export default Details;
