import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', width: '5%', label: 'Id', show: true, sortable: true},
  {accessor: 'content', width: '20%', label: 'Content', show: true, type: 'contentLink',},
  {accessor: 'user', type: 'concatString', show: true, label: 'User'},
  {accessor: 'description', show: true, label: 'Description', width: '30%', type: 'fieldValue'},
  {accessor: 'report_reason.reason', show: true, label: 'Report reason', type: 'fieldValue'},
  {accessor: 'reviewed', name: 'reviewed', show: true, label: 'Reviewed', type: 'boolean', sortable: true,},
];
const fieldSearchable = [
  'id',
    //'content.name',
];
const fieldFilterable = [
  {name: 'reviewed', label: 'Reviewed', type: 'boolean'},
  {name:'reportReason.id',label:'Reason',type:'videoReportReason'}
];
const VideoReportList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`video-reports`}
          exportUrl={`video-reports/export`}
          pageLink ={'/contents/video-reports'}
          searchMessage={'Search (ID)'}
          baseUrl={`video-reports`}
          removeAdd={true}
          noShow={true}
          noDelete={true}
          noDeleteMany={true}
      />
  );
};
export default VideoReportList;
