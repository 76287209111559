import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import useAuth from 'hooks/useAuth';
import { closeModal } from 'slices/modals';
import { LiveSessionMonthIncomesModal, MonthIncomesModal } from 'components/Modals';
import MonthDetailsDefault from 'components/Modals/MonthDetailsDefault';

const ModalsProvider = (props) => {
    const { isAuthenticated } = useAuth();
    const { modals } = useSelector((state) => state.modals);
    const dispatch = useDispatch();
    const modalState = (id, key) => {
      const res = modals.find((modal) => modal.id === id);
      return res[key];
    };
    const handleClose = (id) => {
      dispatch(closeModal(id));
    };
    return isAuthenticated ? (
      <>
         <MonthIncomesModal
          id="month-incomes-modal"
          open={modalState('month-incomes-modal', 'open')}
          data={modalState('month-incomes-modal', 'data')}
          handleClose={handleClose}
        /> 
        <LiveSessionMonthIncomesModal
          id="live-session-month-incomes-modal"
          open={modalState('live-session-month-incomes-modal', 'open')}
          data={modalState('live-session-month-incomes-modal', 'data')}
          handleClose={handleClose}
        /> 
         <MonthDetailsDefault
          id="month-details-defaults-modal"
          open={modalState('month-details-defaults-modal', 'open')}
          data={modalState('month-details-defaults-modal', 'data')}
          handleClose={handleClose}
        /> 
      </>
    ) : null;
  };
  
  ModalsProvider.propTypes = {};
  
  export default ModalsProvider;
  