import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  TextField,
  Box,
  MenuItem,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import { api_get, api_post } from "../utils/Api";
import Moment from 'moment';
import CheckForm from './CheckForm';
import SnackBar from "./SnackBar";
import {makeStyles} from "@material-ui/styles";
import optionsParser from "../helpers/optionsParser";
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
const AutogenerateCodeDialog = (props)=> {
  const theme = useTheme();
  const {getListData, rowsPerPage} = props;
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [accounts, getAccounts] = useState([]);
  const [postalAccounts, getPostalAccounts] = useState([]);
  const [offices, getOffices] = useState([]);
  const [agencies, getAgencies]=useState([]);
  const [values, setValues] = useState({
    amount: 0,
    comment: '',
    type: 'cash',
    office: '',
    account: '',
    numCheck: '',
    phone: '',
    fullname: '',
    checkDate: Moment().format('YYYY-MM-DD'),
    posteType: 'cards',
    agency: '',
  });
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('success');
  const [open, setOpen] = React.useState(false);
  const [openMessage, setOpenMessageModal] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAlert= () => {
    setOpenAlert(false);
  };
  const handleMessageClose = () => {
    setOpenMessageModal(false);
  };
  const handleCloseErrorMessage = () => {
    setOpen(false);
  };
  useEffect(() => {
    api_get(`agencies?perPage=50`).then((data)=>{
      getAgencies(data.payload);
    });
    api_get(`company-accounts`).then((data) => {
      getAccounts(data.payload);
      const cards = data.payload.filter((account) => account.type == 'poste');
      getPostalAccounts(cards);
    });

    api_get(`offices?perPage=99`).then((data)=>{
      getOffices(...offices,data.payload);
    });
  


  }, []);
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpenMessageModal(true);
      }
    });
  };

  const formValidation = (values) => {
    if (!values?.amount) {
      return { success: false, 'message': 'Amount is missing' };
    }
    if (!values?.office) {
      return { success: false, 'message': 'Office is missing' };
    }
    if ((values?.type == 'check' || values?.type == 'transfer'|| (values?.type == 'poste' && values.posteType == 'cards')) && !values?.account) {
      return { success: false, 'message': 'Account is missing' };
    }
    if(values.type == 'poste' && values.posteType == 'cards' && !values.comment){
      return { success: false, 'message': 'Num Emission is missing' };
    }
    if(values.type == 'poste' && values.posteType == 'cards' && !values.comment){
      return { success: false, 'message': 'Num Emission is missing' };
    }
    if(values.type == 'poste' && values.posteType == 'mandates' && !values.comment){
      return { success: false, 'message': 'Num Mandat is missing' };
    }
    if(values.type == 'check' && !values.numCheck){
      return { success: false, 'message': 'NumCheck is missing'};
    }
    if(values.type == 'check' && !values.agency){
      return { success: false, 'message': 'Agency is missing'};
    }
    if(values.type == 'check' && !values.numCheck){
      return { success: false, 'message': 'NumCheck is missing'};
    }
    if(values.type == 'check' && !values.fullname){
      return { success: false, 'message': 'FullName is missing'};
    }
    if(values.type == 'check' && !values.phone){
      return { success: false, 'message': 'Phone is missing'};
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpenAlert(true);
    }
    else {
      api_post(`codes/autogenerate`, values).then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else {
          setAlertSeverity('success');
          setOpenMessageModal(true);
          setAlertMessage('code created successfully');
          setOpen(false);
          setValues({
            amount: 0,
            comment: '',
            type: 'cash',
            office: '',
            account: '',
            numCheck: '',
            phone: '',
            fullname: '',
            checkDate: Moment().format('YYYY-MM-DD'),
            posteType: 'cards',
            agency: '',
          });
          getListData(rowsPerPage)
        }
      }).catch((error) => {
        throw new Error(error);
      });
    }
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const agencyChange = (event, value) => {
    setValues({...values, 'agency': value ? value.id : null});
  };
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Autogenerate
      </Button>
      <SnackBar open={openMessage} message={message} severity={severity} handleClose={handleMessageClose}/>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Add Code</DialogTitle>
        <DialogContent>
          <Box m={1} pt={1}>
            <TextField
              autoFocus
              margin="dense"
              type="number"
              name="amount"
              label="Amount"
              value={values.amount || ''}
              onChange={handleChange}
              fullWidth
            />
            <FormControl component="fieldset">
              <RadioGroup aria-label="Type" name="type"
                onChange={handleChange}
                value={values.type || ''}
                row>
                <FormControlLabel control={<Radio/>} value="cash"
                  label="Cash" labelPlacement="bottom"/>
                <FormControlLabel control={<Radio/>} value="check"
                  label="Check" labelPlacement="bottom"/>
                <FormControlLabel control={<Radio/>} value="transfer"
                  label="Transfer" labelPlacement="bottom"/>
                <FormControlLabel control={<Radio/>} value="poste"
                  label="Poste" labelPlacement="bottom"/>
              </RadioGroup>
            </FormControl>
            <TextField
              autoFocus
              fullWidth
              select
              name="office"
              id="office"
              label="Office"
              value={values.office || ''}
              onChange={handleChange}
            >
              {
                offices.map((office)=>{
                  return (
                    <MenuItem key={office.id} value={office.id}>{office.name}</MenuItem>);
                })
              }
            </TextField>
            <Box m={1} pt={1} hidden={values.type != 'poste'}>
              <FormControl component="fieldset">
                <RadioGroup aria-label="posteType" name="posteType"
                  onChange={handleChange}
                  value={values.posteType || ''}
                  row>
                  <FormControlLabel control={<Radio/>} value="cards"
                    label="Postals Cards" labelPlacement="bottom"/>
                  <FormControlLabel control={<Radio/>} value="mandates"
                    label="Minutes Mandates" labelPlacement="bottom"/>
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box m={1} pt={1} hidden={values.type == 'check' || values.type == 'transfer'}>
            <TextField
              autoFocus
              margin="dense"
              name="comment"
              id="comment"
              label={values.type === 'poste' ?(values.posteType =='cards'? 'Num Emission': 'Num Mandat' ) : 'Comment'}
              value={values.comment || ''}
              onChange={handleChange}
              fullWidth
            />
          </Box>
          <Box m={1} pt={1} hidden={values.type == 'cash'|| values.type == 'poste'}>
            <TextField
              autoFocus
              fullWidth
              select
              name="account"
              id="account"
              label="Account"
              value={values.account || ''}
              onChange={handleChange}
            >
              {
                accounts.map((account)=>{
                  return (
                    <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>);
                })
              }
            </TextField>
          </Box>
          <Box m={1} pt={1} hidden={values.type !== 'transfer'}>
            <Autocomplete
                id="combo-box-demo"
                name="agency"
                options={agencies}
                value={values?.agency?.id}
                onChange={agencyChange}
                getOptionLabel={(option) => `${option.abbreviation }` !== 'null' ? `[${option.abbreviation}] ${option.fr_name}` : `${option.fr_name}`}
                renderInput={(params) => <TextField {...params}
                                                    label="Choose an agency"
                />
                }
            />
          </Box>
          <Box m={1} pt={1} hidden={values.posteType != 'cards' || values.type != 'poste'}>
            <TextField
                autoFocus
                fullWidth
                select
                name="account"
                id="account"
                label="Account"
                value={values.account || ''}
                onChange={handleChange}
            >
              {
                postalAccounts.map((account)=>{
                  return (
                      <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>);
                })
              }
            </TextField>
          </Box>
          <Box m={1} pt={1} hidden={values.type != 'check'}>
            <CheckForm
                values = {values}
                setValues={setValues}
                handleChange={handleChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar open={openAlert} message={message} severity={severity}
                handleClose={handleCloseAlert}/>
    </div>
  );
};
export default AutogenerateCodeDialog;

