import React, {useEffect, useState} from 'react';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {api_get, api_post} from '../utils/Api';
import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core';
import SnackBar from './SnackBar';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {makeStyles} from '@material-ui/styles';
import optionsParser from '../helpers/optionsParser';
import {AsyncPaginate} from 'react-select-async-paginate';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    minWidth: '500px',
    [theme.breakpoints.down('xs')]: {
        minWidth: 300}
},
}));

const BuyForStudentDialog = (props) => {
    const {noButton,openModal, initializeData, rowsPerPage, ...rest} = props;
    const theme = useTheme();
      const classes = useStyles();
      const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
      const [discounts, setDiscounts] = useState([
        {'name': 'Réduction en pourcentage', 'value': 'percentage_discount'},
        {'name': 'Réduction fixe', 'value': 'fixe_discount'},
        {'name': 'Prix fixe', 'value': 'fixe_price'},
      ]);
      const [values, setValues] = useState({
        "quantity": 1,
        "note": "",
        "discount_method": "",
        "discount_value": null,
        "rest_year": false,
        "offer": null,
        "user": null,
          "offer_subject_details": null
      });
      const [message, setAlertMessage] = useState('');
      const [severity, setAlertSeverity] = useState('success');
      const [open, setOpen] = React.useState(false);
      const [openAlert, setOpenAlert] = React.useState(false);
      const [openMessage, setOpenMessageModal] = useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
      const handleCloseAlert= () => {
          setOpenAlert(false);
      };
      const handleMessageClose = () => {
        setOpenMessageModal(false);
      };
      const handleCloseErrorMessage = () => {
        setOpen(false);
      };
      useEffect(() => {
      }, []);
      const showErrorMessage = (errors) => {
        Object.keys(errors).map(function(key, index) {
          if (index == 0) {
            setAlertSeverity('error');
            setAlertMessage(errors[key][0]);
            setOpenMessageModal(true);
          }
        });
      };
    const formValidation = (values) => {
        if (!values?.offer) {
            return { success: false, 'message': 'Offer is missing' };
        }
        if (!values?.user) {
            return { success: false, 'message': 'User is missing' };
        }
        if (!values?.offer) {
            return { success: false, 'message': 'Offer is missing' };
        }
        if (!values?.quantity && !values?.rest_year) {
            return { success: false, 'message': 'Choose quantity is missing' };
        }
        if (values?.quantity && parseInt(values?.quantity) === 0 && !values?.rest_year) {
          return { success: false, 'message': 'Quantity value must be > 0' };
        }
        if (values?.discount_value && parseInt(values?.discount_value) === 0) {
          return { success: false, 'message': 'Discount value must be > 0' };
        }
        
    };
    const handleSubmit = () => {
        const validation = formValidation(values);
        if (validation) {
            setAlertMessage(validation.message);
            setAlertSeverity('warning');
            setOpenAlert(true);
        }
        else {
            values.offer = values.offer.id;
            values.user = values.user.id;
            api_post(`buy-for-user`, values).then((result) => {
              if(result?.errors) {
                showErrorMessage(result.errors);
              }
                else if(result?.code !== 200 && result?.code !== 201){
                    setAlertSeverity('warning');
                    setAlertMessage(result?.message);
                    setOpenAlert(true);
                } else {
                    setAlertSeverity('success');
                    setOpenMessageModal(true);
                    setAlertMessage('Affiliation created successfully');
                    setOpen(false);
                    setValues({
                        amount: 0,
                        note: '',
                        user: null,
                        offer: null,
                        quantity: 0,
                        discount_method: "",
                        discount_value: 0,
                        rest_year: false,
                    });
                    initializeData(rowsPerPage);
                    //window.location.href = '/finance/affiliations';
                }
            }).catch((error) => {
                throw new Error(error);
            });
        }
      };
      const handleChange = (event) => {
        setValues({
          ...values,
            [event.target.name]:
                event.target.type === 'checkbox' ?
                    event.target.checked :
                    event.target.value,
        });
      };
      const getOffers = async (search, prevData, page) => {
        const options = optionsParser(search, null, null, ['name']);
        const result = await api_get(`offers?page=${page.page}${options}`);
        return {
          options: [...result.payload],
          hasMore: result.meta.current_page !== result.meta.last_page,
          additional: {
            page: result.meta.current_page + 1,
          },
        };
      };
      const groupChange = (value) => {
        setValues(
            {
              ...values,
              offer: value,
            });
      };
      const userChange = (value) => {
        setValues(
            {
              ...values,
              user: value,
            });
      };
      const getUsers = async (search, prevData, page) => {
        const options = optionsParser(search, null, null, ['name', 'lastName', 'id', 'phone', 'email']);
        const result = await api_get(
            `users?search=type.id=1&page=${page.page}${options}`);
        return {
          options: [...result.payload],
          hasMore: result.meta.current_page !== result.meta.last_page,
          additional: {
            page: result.meta.current_page + 1,
          },
        };
      };
      return (
          <div>
              {!noButton &&
              <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                      Buy for student
                  </Button>
              }

            <SnackBar open={openMessage} message={message} severity={severity}
                      handleClose={handleMessageClose}/>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                classes={{paper: classes.paper}}
            >
              <DialogTitle id="responsive-dialog-title">Buy offer</DialogTitle>
              <DialogContent >
                <Box mt={1} >
                  <AsyncPaginate
                      loadOptions={getOffers}
                      value={values.offer}
                      onChange={groupChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder="Offer"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '30px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('MuiPaper-root')}
                  />
                </Box>
                <Box mt={2}>
                  <AsyncPaginate
                      loadOptions={getUsers}
                      value={values.user}
                      onChange={userChange}
                      getOptionLabel={(option) => '[' + option.id + ']' + ' ' +
                          option.name + ' ' + option.last_name +' '+ (option?.phone ? option.phone : '')+' '+ (option?.email ? '['+option?.email+']' : '')}
                      getOptionValue={(option) => option.id}
                      placeholder="User"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        
                        control: (base) => ({
                          ...base,
                          height: '30px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('MuiPaper-root')}
                  />
                </Box>
                <Box m={0} pt={1}>
                  <TextField 

                      autoFocus
                      margin="dense"
                      type="number"
                      variant="outlined"
                      name="quantity"
                      label="Quantity"
                      style= {{width:"fullWidth",zIndex:0,position:'relative'}}
                      value={values.quantity || ''}
                      onChange={handleChange}
                      fullWidth
                  />
                </Box>
                <Box m={1} pt={1}>
                  <FormControlLabel
                      control={
                        <Switch
                            checked={values.rest_year}
                            onChange={handleChange}
                            name="rest_year"
                            color="primary"
                            disabled={values?.offer?.type === 'pack'?true:false}
                        />
                      }
                      label="Rest year"
                  />
                </Box>
                <Box>
                  <TextField
                      autoFocus
                      fullWidth
                      select
                      variant="outlined"
                      style= {{width:"fullWidth",zIndex:0,position:'relative'}}
                      size="small"
                      name="discount_method"
                      id="discount"
                      label="Discount"
                      value={values.discount_method || ''}
                      onChange={handleChange}
                  >
                    {
                      discounts.map((discount) => {
                        return (
                            <MenuItem key="discount-value" value={discount.value}>{discount.name}</MenuItem>)
                      })
                    }
                  </TextField>
                </Box>
                <Box m={0} pt={1}>
                  <TextField
                      autoFocus
                      margin="dense"
                      type="number"
                      variant="outlined"
                      name="discount_value"
                      label="Discount value"
                      value={values.discount_value || ''}
                      onChange={handleChange}
                      fullWidth
                      style={{zIndex:0,position:'relative'}}
                  />
                </Box>
                <Box m={0} pt={1}>
                  <TextField
                      autoFocus
                      margin="dense"
                      variant="outlined"
                      type="text"
                      name="note"
                      label="Note"
                      value={values.note || ''}
                      onChange={handleChange}
                      fullWidth
                      style={{zIndex:0,position:'relative'}}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" autoFocus>
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
              <SnackBar open={openAlert} message={message} severity={severity}
                        handleClose={handleCloseAlert}/>
          </div>
      )

    }

export default BuyForStudentDialog;