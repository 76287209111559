import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id',label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '45%', show: true},
  {accessor: 'admin_groups', type: 'simpleList', field:'name', color: 'primary', label: 'Role', width: '45%', show: true},
];
const fieldSearchable=[
    'id',
    'name'
];
const fieldFilterable = [
  {name: 'email', label: 'Email', type: 'text'},
];
const AdminUserList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`admin-users`}
          exportUrl={`admin-users/export`}
          pageLink ={'/settings/admin-users'}
          searchMessage={'Search (ID, Name)'}
          baseUrl={`admin-users`}
          noShow={true}
      />
  );
};

export default AdminUserList;
