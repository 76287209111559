import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';

import Toolbar from '../../components/Toolbar/Toolbar'
import Table from '../../components/Table/Table'
import Collapse from '@material-ui/core/Collapse';
import Columns from '../../components/Columns';
import Filter from '../../components/Filter/Filter';
import optionsParser from '../../helpers/optionsParser';
import Loading from '../../components/Loading/Loading';
import { api_get } from 'utils/Api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '30%', show: true},
  {accessor: 'name', label: 'Name', width: '30%', show: true},
  {accessor: 'is_public', label: 'Public', width: '30%', show: true},
];
const fieldSearchable=[
];
const fieldFilterable = [
];
const MessageList = () => {
  const [search, searchChange] = useState('');
  const [filters, filtersChange] = useState({});
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({accessor: "id",order: "desc"});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(24);

  useEffect(() => {
    getData();
  }, [page, sort]);

  useEffect(() => {
    if ((search.length || Object.keys(filters).length)) {
      setPage(1);
      getData();
    }
  }, [filters, search]);

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const getData = (perPage = 24)=>{
    const options = optionsParser(search, filters, sort);
    api_get(`messages?page=${page}${options}&perPage=${perPage}`).then((data)=>{
      setData(data);
    });
  };

  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    getData(event.target.value)
  };
  const classes = useStyles();
  if (!data.data) {
    return (<Loading/>);
  }
  return (
    <div className={classes.root}>
      <Toolbar toggleFilters={toggleFilters} toggleColumns={toggleColumns} searchChange={searchChange}/>
      <div className={classes.content}>
        <Collapse in={columnsOpen}>
          <Columns columnsChange={columnsChange} columns={columns}/>
        </Collapse>
        <Collapse in={filtersOpen}>
          <Filter fields={fieldFilterable} values={filters} filtersChange={filtersChange}/>
        </Collapse>
        <Table columns={columns} data={data} handleSortChange={handleSortChange} sort={sort}
               handlePageChange={handlePageChange}
               page={page}
               rowsPerPage={rowsPerPage}
               handleRowsPerPageChange={handleRowsPerPageChange}
               deleteUrl={`delete/many-messages `}
               keyValue={"messages"}

        />
      </div>
    </div>
  );
};

export default MessageList;
