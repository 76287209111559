import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {ArrowBack} from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SnackBar from '../../../components/SnackBar';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import {Link} from 'react-router-dom';
import {api_get} from "../../../utils/Api";
import optionsParser from '../../../helpers/optionsParser';
import EventPenaltyForm from "./EventPenaltyForm";
import moment from 'moment';
import TablePaginationActions from 'components/Table/TablePaginationActions';
import Loading from '../../../components/Loading/Loading';


const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      maxWidth: 360,
      display: 'flex',

    },
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  heading: {
    fontWeight: '400',
    marginLeft: theme.spacing(2),
  },
  listItem: {
    padding: 0,
  },
  pagination: {
    flexGrow: 1,
   },

}));

const EventsForm = (props) => {
  const {id} = useParams();
  const {className,edit, meta, ...rest} = props;
  const [open, setOpen] = useState(false);
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertseverity] = useState('');
  const [teacherEvents, setTeacherEvents] = useState([]);
  const [EventPenalties, setEventPenalties] = useState([]);
  const [isEditClicked, setIsEditCliked] = useState(false);
  const [eventId, setEventId] = useState('');
  const [instructorId, setInstructorId] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(24);


  useEffect(() => {
    getData();
}, [id, page, rowsPerPage]);


  const handlePageChange = (event, page) => {
      setPage(page + 1);
  };

  const handleRowsPerPageChange = (event) => {
      setRowsPerPage(event.target.value);
    };

  const getData = async (search, prevData, perPage = 24) => {
    const options = optionsParser(search, null, null, ['name']).replace( '&search=', ';' );
    api_get(`events?search=content.instructor.id:${id}${options}&searchJoin=and&page=${page}&orderBy=id&sortedBy=desc&perPage=${rowsPerPage}`).then((data) => {
      setTeacherEvents(data.payload);
      setData(data);
     })
    }
 
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickEditIcon = () => {
    setIsEditCliked(true) ;
  };

  const EventPenaltyFormat = (event) => {
    let newArr = []
    if(event?.event_penalties?.length > 0) {
        event.event_penalties.map(penalty => {
         newArr.push({
           "canceled": penalty.canceled  === true ? '1' : '0',
           "confirmed": penalty.confirmed  === true ? '1' : '0',
           "event": event?.id,
           "instructor": event?.content?.instructor?.id,
           "note": penalty.note,
           "penaltyDetail": penalty?.penalty_detail?.id,
         });
       });
      }
    return newArr;
  };

  const handleRowClick = (event) => {
    setEventId(event?.id);
    setInstructorId(event?.content?.instructor?.id);
    setEventPenalties(EventPenaltyFormat(event));
    };

  const tableCell = [
      'id', 
      'Title',
      'Division',
      'Created At',
      'Start Date',
      'Action',
    ];
    
  const classes = useStyles();
    if (!data.payload) {
        return (<Loading/>);
    }

  return (
    <div>
      {isEditClicked &&
         <div className={classes.row}>
            <IconButton onClick={()=> setIsEditCliked(false)}>
              <ArrowBack fontSize="inherit" />
            </IconButton>
            <Typography variant="h2" component="h2" className={classes.heading}>
              {"Event penalties"}
            </Typography>
          </div> }

            {isEditClicked ? (       
            <EventPenaltyForm eventId={eventId} instructorId={instructorId} EventPenalties={EventPenalties} /> ) : (
              <Grid
                container
                spacing={3}
              >
                <Card
                  {...rest}
                  className={clsx(classes.root, className)}
                >
                    {teacherEvents?.length ? (
                          <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  {tableCell.map((tableCell) => {
                                    return (
                                      <TableCell>{tableCell}</TableCell>
                                    );
                                  })}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {teacherEvents.map((event) => (
                                  <TableRow key={event?.id} onClick={() => handleRowClick(event)}>
                                    <TableCell component="th" scope="row">
                                      {event?.id}
                                    </TableCell>
                                    <TableCell>{event?.content?.name}</TableCell>
                                    <TableCell>
                                    <List>
                                        {event?.subject_events?.map((item) => (
                                            <ListItem key={item.id} className={classes.listItem}>
                                              <Chip
                                                  label={item?.subject?.division?.name}
                                                  style={{backgroundColor:'#FD9D59', color:'white'}}/>
                                            </ListItem>
                                        ))}
                                      </List>
                                    </TableCell>
                                    <TableCell>
                                      <Chip
                                       size="small"
                                       color={'primary'}
                                       label={moment(event?.created_at).format('DD/MM/YYYY').toString()}/>
                                    </TableCell>
                                    <TableCell>
                                      <Chip
                                       size="small"
                                       color={'primary'}
                                       label={moment(event?.start_date).format('DD/MM/YYYY').toString()}/>
                                    </TableCell>
                                    <TableCell>
                                      <IconButton
                                        className={classes.signOutButton}
                                        color="inherit"
                                        component={Link}
                                        onClick={handleClickEditIcon}
                                        to={`/users/teachers/edit/${event?.content?.instructor?.id}`}>
                                        <EditIcon/>
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                      ) : (
                          <CardContent>
                            <Typography variant="body2" color="textSecondary"
                              component="p">
                              This Teacher does not have any events
                            </Typography>
                          </CardContent>
                      )}
                </Card>
                <CardContent className={classes.pagination} >
                <TablePagination
                      component="div"
                      count={data.meta?.total}
                      onPageChange={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      page={page -1}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[24, 48, 100]}
                      ActionsComponent={TablePaginationActions}
                 /> 
                </CardContent>  
                <SnackBar open={open} message={message} severity={severity}
                  handleClose={handleClose}/>
              </Grid>)}
            </div>
  );
};
EventsForm.propTypes = {
  className: PropTypes.string,
};

export default EventsForm;
