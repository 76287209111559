// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import { api_get, api_post, api_put } from "../../../utils/Api";
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
}));
type Props = {
  edit: boolean,
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    is_public: '',
  });
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('success');

  useEffect(() => {
    if (edit) {
      api_get(`messages/${id}`).then((result)=>{
        const {data}=result;
        setValues({
          name: data.name,
          is_public: data.is_public,
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage=(errors)=>{
    Object.keys(errors).map(function(key, index) {
      if (index==0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const handleSubmit = () => {
    if (edit) {
      api_put(`messages/${id}`, values).then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else {
          setAlertSeverity('success');
          setAlertMessage(' updated successfully');
          setOpen(true);
          window.location.href = '/marketing/messages';
        }
      });
    } else {
      api_post(`messages`, values).then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else {
          setAlertSeverity('success');
          setAlertMessage('user created successfully');
          setOpen(true);
          window.location.href = '/marketing/messages';
        }
      }).catch((error) => {
        throw new Error(error);
      });
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form
          autoComplete="off"
          noValidate
          
        >
          <CardHeader
            subheader="Messages form"
            
          />
          <Divider/>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth

                  label="Name"

                  name="name"
                  onChange={handleChange}
                  required
                  value={values.name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormControl component="fieldset">
                  <FormLabel component="legend">Public</FormLabel>
                  <RadioGroup aria-label="Public" name="is_public"
                    onChange={handleChange}
                    value={values.is_public || ''} row>
                    <FormControlLabel control={<Radio/>} value="1"
                      label="Yes"/>
                    <FormControlLabel control={<Radio/>} value="0"
                      label="No"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

          </CardContent>
          <Divider/>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}>
                Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar open={open} message={message} severity={severity} handleClose={handleClose}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Form;
