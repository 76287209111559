import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import TableList from "../../components/List/TableList";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
const columnsList = [
  {accessor: 'id', name : 'id',label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'user', label: 'User', show: true ,type:'concatString'},
  {accessor: 'group.name', label: 'Group', show: true},
  {accessor: 'division.name', label: 'Division', show: true},
  {accessor: 'amount_paid', name : 'amountPaid', label: 'Amount paid', show: true, sortable: true, type: 'fieldValue'},
  {accessor: 'quantity', name : 'quantity',label: 'Quantity', show: true, sortable: true, type: 'fieldValue'},
  {accessor: 'responsible', label: 'Source', show: true, type: 'fieldValue'},
  {accessor: 'start_date', name: 'startDate',label: 'Start Date', show: true, sortable: true, type: 'dateTime'},
  {accessor: 'end_date', name: 'endDate', label: 'End Date', show: true, sortable: true, type: 'dateTime'},
  {accessor: 'active', type: 'boolean', color: 'primary', label: 'Active', show: true,},
  {accessor: 'note', type: 'fieldValue', name: 'note', label: 'Note', show: false,},
  {accessor: 'created_at', type: 'dateTime', name: 'created_at', label: 'Created At', sortable: true, show: false,},
  {accessor: 'payment_agreements', type: 'affilliationSlicesType', label: 'Slices', show: false},

];

const fieldSearchable = [
  'id',
];
const fieldFilterable = [
  {name: 'user.id', label: 'User', type: 'userAutocomplete'},
  {name: 'group.id', label: 'Group', type: 'userGroupAutocomplete'},
  {name: 'division.id', label: 'Division', type: 'divisionAutocomplete'},
  {name: 'traineeship.id', label: 'Hessaty Traineeships', type: 'traineeshipAutocompleteFilter'},
  {name: 'startDate', label: 'Start date', type: 'dateRange'},
  {name: 'endDate', label: 'End date', type: 'dateRange'},
  {name: 'quantity', label: 'Quantity', type: 'number'},
  {name: 'active', label: 'Active', type: 'boolean'},
];
const AffiliationList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`affiliations`}
          exportUrl={`affiliations/export`}
          pageLink ={'/finance/affiliations'}
          searchMessage={'Search (ID)'}
          baseUrl={`affiliations`}
          deleteUrl={`delete/many-affiliations`}
          keyValue={"affiliations"}
          
      />
  );
};

export default AffiliationList;
