import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  list: {
    maxHeight: 320,
  },
  message: {
    padding: theme.spacing(1),
  }
}));

const FilesInfo = ({
                      files,
                      className,
                      ...rest
                    }) => {
  const classes = useStyles();

  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >
        <CardHeader title="Files"/>
        <Divider/>
        {files && files.length > 0 ? (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem divider={i < files.length - 1} key={i}>
                  <ListItemText
                    primary={file.name}
                    style={{overflowWrap: 'break-word'}}
                    primaryTypographyProps={{ variant: "h6" }}
                  />
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
        </>
      ) : (
        <Typography className={clsx(classes.message, className)} variant="body2" color="textSecondary"
          component="p">
            No files
        </Typography>
       )}
      </Card>
  );
};

FilesInfo.propTypes = {
  className: PropTypes.string,
};

export default FilesInfo;
