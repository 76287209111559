// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import Checkbox from '@material-ui/core/Checkbox';
import {DateTimePicker, LocalizationProvider} from '@material-ui/pickers';
import momentAdapter from '@material-ui/pickers/adapter/moment';
import Moment from 'moment';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import optionsParser from '../../../helpers/optionsParser';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import QuillEditor from '../../../components/QuillEditor';
import FilesDropzone from '../../../components/FilesDropzone';
import {api_get, api_post_serialize} from '../../../utils/Api';
import queryString from "query-string";
import FilesList from "../../../components/Toolbar/FilesList";
import LoadingApi from 'components/LoadingApi/LoadingApi';
import {getSubjects, getWebinars} from "../../../helpers/methods";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    overflow: 'visible',
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));

const modules = {
  toolbar: [
    [{header: [1, 2, false]}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      {list: 'ordered'},
      {list: 'bullet'},
      {indent: '-1'},
      {indent: '+1'},
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];
type Props = {
  edit: boolean,
  className: string
}
const FormEdit = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [duration, setDuration] = useState('01:30');
  const [chapter, setChapter] = useState();
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [canceled, setCancel] = useState(null)
  const [reason,setReason]=useState(null)
  const [values, setValues] = useState({
    content: {
      name: '',
      isDraft: false,
      active: true,
      cancel: false,
      description: '',
      instructor: '',
      reason:'',
      //contentFiles: [],
      contentStudentLevels: [],
      groupContents: [],
      type: 5
    },
    startDate: Moment().format('YYYY-MM-DD HH:mm'),
    endDate: Moment().format('YYYY-MM-DD hh:mm'),
    isBigZoomEvent: false,
    webinarAccount: '',
    traineeship: '',
    subjectEvents: [],
    canceled: false,
  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [existZoomAccount, setExistZoomAccount] = useState(false);
  const [subject, setSubject]= useState(null);
  const [webinar, setWebinar] = useState(null);
  const [open, setOpen] = React.useState(false);
  const query = queryString.parse(window.location.search);
  const chapterId =  query.chapter_id;
  const handleClose = () => {
    setOpen(false);
    
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }

  const checkIsZoomAccount = (email) => {
    return email?.includes('zoom') || email?.includes('ostedhy');
  };

  const handleChangeDuration = (duration,startD=null) => {
    const newValues = {...values}
    if (newValues.startDate && duration) {
      let date=startD || newValues.startDate;
      setDuration(duration);
      const endDate = Moment(date).
      add(Moment.duration(duration).asMinutes(),'minute').format('YYYY-MM-DD HH:mm');
      setValues({...newValues,
        endDate: endDate,
        startDate: date,
      });
    }
  };

  const getChapters = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`traineeships?page=${page.page}${options}&search=traineeshipSubjects.subject.id:${subject.id}&searchJoin=and&orderBy=id&sortedBy=desc`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const chapterChange = (value) => {
    setChapter(value)
    setValues({...values, traineeship: value});

  };
  const subjectsChange = (options) => {
    setSubject(options)
    setValues({...values, subjectEvents: options});

};
  useEffect(() => {
    if (chapterId) {
      api_get(`traineeships/${chapterId}`).then((data) => {
        setChapter(data.payload);
       setValues({...values,
          traineeship: data.payload,
        });

      });
    }
      if (edit) {
        getEventDatas()
    }
  }, []);
  
  const getEventDatas = () => {
    setLoading(true)
    api_get(`events/${id}`).then((result) => {
      setLoading(false)
      const payload = result.payload;
      setWebinar(payload.webinar_account)
  
     
      let duration = Moment.duration(
          Moment(payload?.end_date).diff(Moment(payload?.start_date)),
      );
      const hours = parseInt(duration.asHours());
      const minutes = parseInt(duration.asMinutes()) % 60;
      duration = `${hours}:${minutes}`;
      setAttachments(payload.content?.content_files)
      setChapter(payload.traineeship)
      setValues({
        content: {
          name: payload.content.name,
          //description: payload.content.description,
          isDraft: payload.content.is_draft,
          active: payload.content.active,
          instructor: payload.content?.instructor,
          type: 5,
          groupContents: payload.content?.group_contents,
          contentStudentLevels: payload.content?.content_student_levels,
        },
        //duration,
        startDate: Moment(payload?.start_date, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
        endDate: Moment(payload?.end_date, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
        isBigZoomEvent: payload?.is_big_zoom_event ,
        webinarAccount:  payload?.webinar_account,
        traineeship: payload.traineeship,
        canceled: payload?.canceled,
        reason: payload?.reason,
        url: payload.url,
        subjectEvents : payload?.subject_events,
        
      });
     setSubject(payload?.subject_events)
      setCancel(payload.canceled)
    });
  }
  const handleChange = (event) => {
    setValues({
      ...values, content: {
        ...values.content, [event.target.name]: event.target.value,
      }
    });
  };

  const urlChange = (event) => {
    if (event) {
      setValues({...values, url: event.target.value});
    }
  };

  const reasonChange = (event) => {
    if (event) {
       setReason(event.target.value) 
        setValues({...values, reason: event.target.value});
    }
};

  const onFileChange = (files) => {
    if(files && files.length > 0) {
      setValues({
        ...values, content: {
          ...values.content, contentFiles: files,
        }
      });
    }
  };
  const handleDescriptionChange = (value) => {
    setValues({
      ...values, content: {
        ...values.content, description: value,
      }
    });
  };
  const handleCheckChange = (event) => {
    event.persist();
    setValues({
      ...values, content: {
        ...values.content, [event.target.name]: event.target.checked,
      }
    });
  };

  const handleCancelCheckChange = (event) =>  {
    setCancel(event.target.checked)
    setValues({...values, canceled: event.target.checked});
};
  const handleCheckChangeInObject = (event,key) => {
    setValues({...values, isBigZoomEvent: event.target.checked});
  };


  const webinarChange = (value) => {
    if (value) {
      setWebinar(value)
      setValues({...values, webinarAccount: value});
    }
  };
  const dateChange = (value) => {
    if(value){
      setValues({ ...values, startDate: value.format("YYYY-MM-DD HH:mm") });
      handleChangeDuration(duration,value.format('YYYY-MM-DD HH:mm'));
    }
  };

  const endDateChange = (value) => {
    if(value){
        setValues({ ...values, endDate: moment(value, 'YYYY/MM/DD HH:mm').format("YYYY-MM-DD HH:mm") });
    }
};

  const showErrorMessage = (errors) => {
    Object.keys(errors).map((key, index) => {
      if (index == 0) {
        setAlertSeverity('error');
        errors.message ?
            setAlertMessage(errors[0].message) :
            setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const showAlertMessage = (message) => {
    setAlertSeverity('error');
    setAlertMessage(message);
    setOpen(true);
  };
  const subjectFormat = (values) => {
    let newArr = [];
    let fileArr = [];
    let groupArr=[];
    let levelArr=[];
    const groups = (chapter && chapter?.traineeship_groups) ? chapter.traineeship_groups : values.content.groupContents;
    const levels = (chapter && chapter?.traineeship_levels) ? chapter.traineeship_levels : values.content.contentStudentLevels;
    const subjects = (chapter && chapter?.traineeship_subjects) ? chapter.traineeship_subjects : values.subjectEvents;
    values.content.instructor = (chapter && chapter?.instructor) ? chapter.instructor.id : values.content.instructor.id;
    values.webinarAccount = values?.webinarAccount?.id;
    values.content.active = values?.content?.active == true ? 1 : 0;
    values.content.isDraft = values?.content?.isDraft == true ? 1 : 0;
    values.canceled = values?.canceled == true ? 1 : 0;
    groups.map(option=>{
      groupArr.push({"groups":  option.group ? option.group.id : option.groups.id})
    })
    values.content.groupContents = groupArr;
    levels.map(level=>{
      levelArr.push({"studentLevel":level.level ? level.level.id : level.student_level.id})
    })
    values.content.contentStudentLevels = levelArr;
    values.traineeship = chapter ? chapter.id :values.traineeship.id;
    subjects.map(option => {
      newArr.push({
        'subject': option.subject.id,
      });
    });
    values.subjectEvents=newArr;
    if(values.content.contentFiles) {
      values.content.contentFiles.map(option => {
        fileArr.push({
          'position': 0,
          'file': {
            'file':option,
            'name': option.name,
            'attachment': true,
            'solution': false,
            'document': 0,
            'marks': false,
            'homework': false,
          },
        })
      })
      values.content.contentFiles = fileArr

    }
    return values;
  };

  const clearDatas = (values) => {
    setValues({...values,
        traineeship:chapter,
        webinarAccount:webinar,
        subjectEvents:subject,
      canceled:canceled==true? 1 : 0,
    });
};

  const formValidation = (values) => {
    if (!values?.content?.name) {
      return { success: false, 'message': 'Name is missing' };
    }
    if (values?.content?.name.length < 3 || values?.content?.name?.length > 2000) {
      return { success: false, 'message': 'Name must be between 3 and 2000 characters' };
    }
    if (!values.webinarAccount) {
      return { success: false, 'message': 'WebinarAccount is missing' };
    }
    if (values.canceled && !values.reason) {
      return { success: false, 'message': 'Reason is missing' };
    }
  };
 
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      const valuesState = subjectFormat(values);
      setLoading(true);
      const requestValues = mapStateToRequest(valuesState,
          ['content', 'subjectEvents'], []
              /*[
                {'name': 'contentStudentLevels', 'key': 'studentLevel'}],*/
              ['duration']);
        api_post_serialize(`events/${id}`, requestValues, true).then((result) => {
          if(result.errors){
            clearDatas(values)
            showErrorMessage(result.errors)
            

          }else if(result?.code !== 200  && result?.code !== 201){
            showAlertMessage(result?.message? result.message:'Technical error! Contact the Developers Team')
            clearDatas(values)
          }
          else {
            setAlertSeverity('success');
            setAlertMessage('Event updated successfully');
            setOpen(true);
            setLoading(false);
            window.location.href = '/hessaty/content-events';
          }
        });

    }
  };

  return (
      <div {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Card className={classes.root}>
               {/* <CardHeader title="Hessaty Chapter Infos"/>*/}
                <Divider/>
                  {
                    chapterId && !edit ?
                        (
                            <CardContent>
                              <TextField
                                  fullWidth
                                  label="Hessaty Traineeship Name"
                                  name="traineeship Name"
                                  required
                                  disabled
                                  value={chapter ? chapter.name : ''}
                                  variant="outlined"
                              />
                              <Box mt={2} mb={1}>
                              <TextField
                                  fullWidth
                                  label="Hessaty Traineeship ID"
                                  name="traineeship"
                                  required
                                  disabled
                                  value={values.traineeship?.id || ''}
                                  variant="outlined"
                              />
                              </Box>
                            </CardContent>
                        ) :(
                              <CardContent>
                                  <AsyncPaginate
                                    loadOptions={getSubjects}
                                    value={values.subjectEvents}
                                    onChange={subjectsChange}
                                    getOptionLabel={(option) => (option.subject ? option?.subject?.division?.name+ '|'+option?.subject?.name : option?.division?.name+ '|'+option.name) || ''}
                                            getOptionValue={(option) => option.subject ? option.subject.id : option.id}
                                    placeholder="Subjects"
                                    additional={{
                                      page: 1,
                                    }}
                                    styles={{
                                      control: (base) => ({
                                        ...base,
                                        minHeight: '53px',
                                      }),
                                      placeholder: (base) => ({
                                        ...base,
                                        color: '#000',
                                        fontSize: theme.typography.fontSize,
                                        fontFamily: theme.typography.fontFamily,
                                      }),
                                    }}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                                  <Box mt={2} mb={1}>
                                  <AsyncPaginate
                                  loadOptions={getChapters}
                                  onChange={chapterChange}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => chapterId ? chapterId : option.id}
                                  value={values.traineeship}
                                  cacheUniqs={[subject]}
                                  placeholder="Chapters"
                                  noOptionsMessage={() => 'You may choose a subject before'}
                                  additional={{
                                  page: 1,
                                }}
                                  styles={{
                                  control: (base) => ({
                                  ...base,
                                  minHeight: '53px',
                                }),
                                  placeholder: (base) => ({
                                  ...base,
                                  color: '#000',
                                  fontSize: theme.typography.fontSize,
                                  fontFamily: theme.typography.fontFamily,
                                }),
                                }}
                                  menuPortalTarget={document.querySelector('body')}
                                  />
                                  </Box>
                              </CardContent>

                              )

                  }


              </Card>

              <Box mt={3}>
              <Card>
                <CardContent>
                  <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.content.name || ''}
                      variant="outlined"
                      size="small"
                  />
                  <Box mt={2} mb={1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Description
                    </Typography>
                  </Box>
                  <Paper variant="outlined">
                    <QuillEditor
                        className={classes.editor}
                        value={values.content.description || ''}
                        modules={modules}
                        formats={formats}
                        onChange={(value) => handleDescriptionChange(value)}
                    />
                  </Paper>
                  <Box mt={2} mb={1}>
                    <TextField
                        fullWidth
                        label="Url"
                        name="url"
                        onChange={urlChange}
                        required
                        value={values.url || ''}
                        variant="outlined"
                        size="small"
                    />
                  </Box>
                </CardContent>
              </Card>
              </Box>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Date & Duration"/>
                  <Divider/>
                  <CardContent>
                    <Box mt={0} mb={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                          <LocalizationProvider dateAdapter={momentAdapter}>
                            <DateTimePicker
                                renderInput={(props) =>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        {...props} />}
                                value={values.startDate}
                                inputFormat="DD/MM/yyyy HH:mm"
                                autoOk
                                ampm={false}
                                label="Start date"
                                onChange={dateChange}
                                name="startDate"
                                inputVariant="outlined"
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <LocalizationProvider dateAdapter={momentAdapter}>
                            <DateTimePicker
                                renderInput={(props) =>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        {...props} />}
                                value={values.endDate}
                                inputFormat="DD/MM/yyyy HH:mm"
                                autoOk
                                ampm={false}
                                label="End date"
                                onChange={endDateChange}
                                name="endDate"
                                inputVariant="outlined"
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Upload Zone"/>
                  <Divider/>
                  <CardContent>
                    <Box mt={0} mb={1}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Files
                      </Typography>
                    </Box>
                    <FilesDropzone values={values} setValues={setValues}
                                   onFileChange={onFileChange}/>
                    <FilesList attachments ={attachments} getElementDatas={getEventDatas}/>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card className={classes.root}>
                <CardHeader title="Basic"/>
                <Divider className={classes.mtop0}/>
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <AsyncPaginate
                        loadOptions={getWebinars}
                        onChange={webinarChange}
                        getOptionLabel={(option) => option.email}
                        getOptionValue={(option) => option.id}
                        value={values?.webinarAccount || ''}
                        placeholder="Webinars"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: '53px',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: '#000',
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector('body')}
                    />
                  </Box>
                  {checkIsZoomAccount(
                      values?.webinarAccount?.email) &&
                  <Box mt={0}>
                    <FormControlLabel
                        control={
                          <Checkbox
                              checked={values.isBigZoomEvent}
                              onChange={ handleCheckChangeInObject}
                              value={values.isBigZoomEvent}
                              name="isBigZoomEvent"
                              size="small"
                          />
                        }
                        label="Is big zoom account ?"
                    />
                  </Box>
                  }
                </CardContent>
              </Card>
              <Box mt={3}>
                <Card className={classes.root}>
                  <CardHeader title="Status"/>
                  <Divider className={classes.mtop0}/>
                  <CardContent className={classes.mtop0}>
                    <Box mt={0}>
                      <FormControlLabel
                          control={
                            <Checkbox
                            checked={values.content?.active}
                            onChange={handleCheckChange}
                            value={values.content.active}
                            name="active"
                            size="small"
                            />
                          }
                          label="Active ?"
                      />
                    </Box>
                    <Box mt={0}>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={values.content.isDraft}
                                onChange={handleCheckChange}
                                value={values.content.isDraft}
                                name="isDraft"
                                size="small"
                            />
                          }
                          label="is draft ?"
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={3}>
                            <Card className={classes.root}>
                                <CardHeader title="Cancel Event?"/>
                                <Divider className={classes.mtop0}/>
                                <CardContent className={classes.mtop0}>
                                    <Box mt={0}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values?.canceled || ''}
                                                    onChange={handleCancelCheckChange}
                                                    value={values?.canceled || ''}
                                                    size="small"
                                                />
                                            }
                                            label="Canceled ?"
                                        />
                                    </Box>
                                     <Box mt={0}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            variant="outlined"
                                            type="text"
                                            name="reason"
                                            label="Reason"
                                            value={values.reason || ''}
                                            onChange={reasonChange}
                                            className={classes.reasonTextField}
                                            required
                                            fullWidth
                                        />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
            >
              Save details
            </Button>
          </Box>
        </form>
        <SnackBar
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
        />
       <LoadingApi open={loading} onClick={handleCloseLoading}/>
      </div>
  );
};

FormEdit.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,

};

export default FormEdit;
