// @flow
import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {api_get, api_post, api_put} from '../../../utils/Api';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import AdminRoleTable from '../../../components/AdminRoleTable/AdminRoleTable';
import {formatAdminRoleList} from '../../AdminRole/components/Form';
import {getAdminGroups, getOffices} from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
}));

type Props = {
  edit: boolean,
  className: string
}

const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    username: '',
    plainPassword: '',
    email: '',
    roles: [],
    adminGroups: [],
    offices:[],
  });
  const [rolesChanged, setRolesChanged] = useState(false);
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [roles, setRoles] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (edit) {
      api_get(`admin-users/${id}`).then((result) => {
        const {payload} = result;
        setValues({
          name: payload.name,
          username: payload.username,
          email: payload.email,
          roles: payload.roles,
          adminGroups: payload.admin_groups,
          offices:payload.offices
        });
      });
      setRolesChanged(!rolesChanged)
    }
  }, []);
  useEffect(() => {
    api_get(`roles`).then((data) => {
      setRoles(formatAdminRoleList(data.payload));
    });
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const formValidation = (values) => {
    if (!values?.name) {
      return { success: false, 'message': 'Name is missing' };
    }
    if (!values?.username) {
      return { success: false, 'message': 'Username is missing' };
    }
    if (!values?.email) {
      return { success: false, 'message': 'Email is missing' };
    }
     if (!values?.plainPassword && !edit) {
       return { success: false, 'message': 'Password is missing' };
    }
  /*  if (!values?.plainPassword) {
      return { success: false, 'message': 'Password is missing' };
    }*/
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      const requestValues = mapStateToRequest(values, 'roles');
      if (edit) {
        api_post(`admin-users/${id}`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('admin updated successfully');
            setOpen(true);
            window.location.href = '/settings/admin-users';
          }
        });
      } else {
        api_post(`admin-users`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('admin created successfully');
            setOpen(true);
            window.location.href = '/settings/admin-users';
          }
        }).catch((error) => {
          throw new Error(error);
        });
      }
    }
  };
  const adminGroupsChange = (options) => {
    setValues({...values, ['adminGroups']: options});
  };
  const adminOfficesChange = (options) => {
    setValues({...values, ['offices']:options});
  };
  const handleChangeAdminRole = (role, fromRoleTable = false) => {
    let newArr = values.roles;
    if (newArr.indexOf(role) != -1) {
      newArr = newArr.filter(r => r != role);
    } else {
      newArr.push(role);
    }
    setValues(prevState => ({
      ...prevState,
      'roles': newArr,
    }));
    if (fromRoleTable) {
      setRolesChanged(!rolesChanged);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const memoAdminRole = useMemo(() => (
      <AdminRoleTable roles={roles}
                      page={page}
                      setPage={setPage}
                      handleClose={handleClose} message={message}
                      severity={severity} open={open}
                      handleChange={handleChangeAdminRole}
                      checkedRoles={values.roles}/>
  ), [roles, rolesChanged, values.roles]);
  return (
      <div>
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
          <form
              autoComplete="off"
              noValidate
          >
            <CardHeader subheader="AdminUsers form"/>
            <Divider/>
            <CardContent>
              <Grid
                  container
                  spacing={3}
              >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <TextField
                      fullWidth
                      label="Username"
                      name="username"
                      onChange={handleChange}
                      required
                      value={values.username || ''}
                      variant="outlined"
                  />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.name || ''}
                      variant="outlined"
                  />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <TextField
                      fullWidth
                      label="Email Address"
                      name="email"
                      onChange={handleChange}
                      required
                      value={values.email || ''}
                      variant="outlined"
                  />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <TextField
                      fullWidth
                      label="password"
                      name="plainPassword"
                      onChange={handleChange}
                      required
                      value={values.plainPassword || ''}
                      variant="outlined"
                      type="password"
                  />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <AsyncPaginate
                      loadOptions={getAdminGroups}
                      value={values.adminGroups}
                      onChange={adminGroupsChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder="Roles"
                      isMulti
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                  />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <AsyncPaginate
                      loadOptions={getOffices}
                      value={values.offices}
                      onChange={adminOfficesChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder="admin-offices"
                      isMulti
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                  />
                </Grid>
                <Grid
                    item
                    md={12}
                    xs={12}
                >
                  {memoAdminRole}
                </Grid>
              </Grid>
            </CardContent>
            <Divider/>
            <CardActions>
              <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}>
                Save details
              </Button>
            </CardActions>
          </form>
        </Card>
        <SnackBar open={open} message={message} severity={severity}
                  handleClose={handleClose}/>
      </div>
  );
};

Form.propTypes =
    {
      className: PropTypes.string,
      edit: PropTypes.bool,
    }
;

export default Form;
