
import optionsParser from "./optionsParser";
import {api_get} from "../utils/Api";



export const getTeachers = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['id', 'name', 'lastName', 'email', 'phone']);
    const result = await api_get(`instructors?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};

export  const getWebinars = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['email']);
    const result = await api_get(`webinars?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};
export const getOffices = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`offices?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};
export const getAdminGroups = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`admin-groups?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};
export const getGroups = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name'])
        .replace( '&search=', ';' )
        .replace( 'searchJoin=or', ';' );
    const result = await api_get(`groups?page=${page.page}&search=active:1${options}searchJoin=and`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};

export const getUsers = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['id', 'name', 'lastName','email', 'phone']);
    const result = await api_get(`users?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};

export const getParents = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['id', 'name', 'lastName','email', 'phone']);
    const result = await api_get(`parent-users?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};



export const getTraineeships = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`traineeships?page=${page.page}&search=${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};

export const getDivisions = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`divisions?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};

export const getSubjects = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name', 'division.name']);
    const result = await api_get(`subjects?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};

export const getLevels = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`student-levels?page=${page.page}${options}`);

    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};

export const getScopes = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`content-scopes?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};
export const getContentTypes = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`content-types?page=${page.page}${options}&searchJoin=and&orderBy=id&sortedBy=desc`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};
export const getEvents = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['content.name','id']);
    const result = await api_get(`events?page=${page.page}${options}`);

    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};

export const getOffers = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"]);
    const result = await api_get(`offers?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};

export const getDomains = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`domains?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};
export const getOfferDivisionDetails = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['id','division.name', 'offer.name']);
    const result = await api_get(`offer-divisions-details?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        /* hasMore: result.meta.current_page !== result.meta.last_page,
         additional: {
           page: result.meta.current_page + 1,
         },*/
    };
};

export const getSegment = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"]);
    const result = await api_get(`segments?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};
export const getCountries = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"]);
    const result = await api_get(`countries?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};

export const getStates = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"]);
    const result = await api_get(`states?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};


export const getCountryStates = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`1/states?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};

export const getDelegations = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`1/delegations?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
export const getPenalties = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`penalties?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};


export const getPenaltyUnits = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`penalty-units?page=${page.page}${options}`);
    return {
        options: [...result.payload],
        hasMore: result.meta.current_page !== result.meta.last_page,
        additional: {
            page: result.meta.current_page + 1,
        },
    };
};

export const convertMonth = (month) => {
    if (month > 6) return month - 7;
    if (month <= 7) return month + 5;
  };
