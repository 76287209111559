import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  TablePagination,
} from '@material-ui/core';
import Action from '../Action/Action';
import TablePaginationActions from './TablePaginationActions';
import CustomTable from './CustomTable';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
    [theme.breakpoints.down('xs')]: {
      minWidth: 50,
    },
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  hidden: {
    display: 'none',
  },
}));


const Table = (props) => {
  const {className, data, columns, handleSortChange, sort, handlePageChange, ActionDeleteMany, rowsPerPage, handleRowsPerPageChange,page, tableService,
    pageLink, noShow, noDelete, noEdit,replace, noAction,initializeData, offerAction, noPagination, noCheck, deleteUrl, noDeleteMany, keyValue, ...rest} = props;
  const classes = useStyles();

  return (
      <Card
          {...rest}
          className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <CustomTable
                  columns={columns}
                  data={data}
                  sort={sort}
                  handleSortChange={handleSortChange}
                  ActionComponent={Action}
                  ActionDeleteMany={ActionDeleteMany}
                  tableService={tableService}
                  rowsPerPage={rowsPerPage}
                  pageLink={pageLink}
                  noShow={noShow}
                  offerAction={offerAction}
                  noDelete={noDelete}
                  noEdit={noEdit}
                  noAction={noAction}
                  noPagination={noPagination}
                  noCheck={noCheck}
                  replace={replace}
                  initializeData={initializeData}
                  deleteUrl={deleteUrl}
                  noDeleteMany={noDeleteMany}
                  keyValue= {keyValue}
              />
            </div>
          </PerfectScrollbar>
        </CardContent>
        {
          !noPagination &&
          <CardActions className={classes.actions} >
            <TablePagination
                component="div"
                count={data?.meta?.total}
                //onChangePage={handlePageChange}
                onPageChange={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                //onRowsPerPageChange={() => {}}
                page={page-1}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[24, 48, 100]}
                ActionsComponent={TablePaginationActions}
            />
          </CardActions>
        }

      </Card>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.object,
  handleSortChange: PropTypes.func,
  sort: PropTypes.object,
  handlePageChange: PropTypes.func,
  page: PropTypes.number,
  deleteUrl: PropTypes.string,

};

export default Table;
