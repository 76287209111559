import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {api_get} from "../../../utils/Api";
import PersonIcon from '@material-ui/icons/PersonOutline';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const BasicsInfo = ({
                      user,
                      className,
                      ...rest
                    }) => {
  const classes = useStyles();
  const TeacherUrl = process.env.REACT_APP_FRONT_TEACHER_URL;
  const switchTeacher = (event, id) => {
    api_get(`users/login-as/${id}`).then((data) => {
      window.open(`${TeacherUrl}/switch?token=${data.payload?.token}`);
    });
  };
  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >
        <CardHeader title="User info"/>
        <Divider/>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                ID
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Email
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.email ?? 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Phone
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.phone ?? 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Full Name
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.full_name}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box
            p={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
        >
          <Button startIcon={<PersonIcon/>}
                  onClick={(event) => switchTeacher(event, user.id)}>
            Login as Teacher
          </Button>
        </Box>
      </Card>
  );
};

BasicsInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
};

export default BasicsInfo;
