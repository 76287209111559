// @flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import { api_get, api_post, api_put } from "../../../utils/Api";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import SalaryForm from "./SalaryForm";
import EventsForm from "./EventsForm";
import LoadingApi from "components/LoadingApi/LoadingApi";
import { getSubjects } from "../../../helpers/methods";
import IncomesForm from "./Incomes/IncomesForm";
import { useStore } from "contexts/JWTAuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
}));
type Props = {
  edit: boolean,
  className: string,
};
const Form = (props: Props): React$Element<any> => {
  const { id } = useParams();
  const { className, edit, isEditClicked, setIsEditCliked, ...rest } = props;
  const classes = useStyles();
  const [tab, setTab] = useState(1);
  const [values, setValues] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: null,
    plainPassword: '',
    verified: "0",
    isAdminTeacher:"0",
    isSingleMachine: "0",
    //machineId: null,
    type:2,
    istest:0,
    //isschool:0,
    completed:1,
    confirmed:0,
    instructorSubjects : [
    ]
  });
  const { user } = useStore();
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertseverity] = useState("");
  const [subjectTeacher, setSubjects] = useState([]);
  const [instructorPrices, setInstructorPrices] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [eventPenaltiesCreate, setEventPenaltiesCreate] = useState(false);
console.log("values", values)
  useEffect(() => {
    if (props.edit) {
      setLoading(true);
      api_get(`instructors/${id}`).then((result) => {
        setLoading(false);
        const { payload } = result;
        let subjects = [];
        payload.instructor_subjects.map((instructorSub) => {
          subjects.push(instructorSub.subject);
        });
        setSubjects(subjects);
        setInstructorPrices(payload?.instructor_prices);
        setValues({
          name: payload.name,
          lastName: payload.last_name,
          email: payload.email,
          phone: payload.phone,
          gender: payload.gender,
          type: 2,
          verified: payload?.verified  ? "1" : "0",
          isAdminTeacher: payload?.is_admin_teacher ? "1": "0",
          isSingleMachine: payload.is_single_machine ? "1": "0",
          //machineId: payload?.machine_id ? payload?.machine_id : null,
          istest: payload.istest,
          confirmed: payload.confirmed,
          completed: payload.completed,
          //isschool: payload.isschool,
          instructorSubjects: subjects,
          divisions: payload.subjects,
        });
      });
    }
  }, [id, props.edit]);

  useEffect(() => {
    if(user.roles.includes("ROLE_SUPER_ADMIN")){
      setEventPenaltiesCreate(true);
    }
    user.admin_groups.map((group) => {
      if (group.name.includes("PENALTIES") ) {
        setEventPenaltiesCreate(true);
      }
    });
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertseverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const switchTeacher = () => {
    api_get(`users/login-as/${id}`).then((data) => {
      window.open(
        `${process.env.REACT_APP_FRONT_TEACHER_URL}/switch?token=${data.payload?.token}`
      );
    });
  };
  const formatInstrcutorSubject = (values) => {
    let newArr = [];
    subjectTeacher &&
      subjectTeacher.map((value) => {
        newArr.push({ subject: value.id });
      });
    values["instructorSubjects"] = newArr;
    return values;
  };
  const clearDatas = (values) => {
    setValues({ ...values, instructorSubjects: [] });
  };

  const registerValidation = (values) => {
    if (!values.name) {
      return { success: false, message: "First name is missing" };
    }
    if (values?.name.length < 3 || values?.name?.length > 2000) {
      return {
        success: false,
        message: "First name must be between 3 and 2000 characters",
      };
    }
    if (!values.lastName) {
      return { success: false, message: "Last name is missing" };
    }
    if (values?.lastName.length < 3 || values?.lastName?.length > 2000) {
      return {
        success: false,
        message: "Last name must be between 3 and 2000 characters",
      };
    }
    if (!values.phone) {
      return { success: false, message: "Phone is missing" };
    }
    if (values.phone.length != 8 || isNaN(values.phone)) {
      return { success: false, message: "Phone number must be a 8 digit" };
    }
    if (!values.email) {
      return { success: false, message: "Email is missing" };
    }
    if ((!values.plainPassword || values.plainPassword < 8) && !edit) {
      return { success: false, message: "Password is missing" };
    }
  };
  const handleSubmit = async () => {
    const validation = registerValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertseverity("warning");
      setOpen(true);
    } else {
      let stateValues = formatInstrcutorSubject(values);
      setLoading(true);
      const requestValues = mapStateToRequest(
        stateValues,
        ["instructorSubjects"],
      );
      if (props.edit) {
        api_put(`instructors/${id}`, requestValues).then((result) => {
          if (result?.errors) {
            showErrorMessage(result.errors);
          } else if (result?.code !== 200 && result?.code !== 201) {
            setAlertseverity("warning");
            setAlertMessage(
              result?.message
                ? result.message
                : "Technical error! Contact the Developers Team"
            );
            setOpen(true);
          } else {
            setAlertseverity("success");
            setAlertMessage("user updated successfully");
            setOpen(true);
            setLoading(false);
            window.location.href = "/users/teachers";
          }
        });
      } else {
        api_post("instructors", requestValues)
          .then((result) => {
            if (result?.errors) {
              showErrorMessage(result.errors);
            } else if (result?.code !== 200 && result?.code !== 201) {
              setAlertseverity("warning");
              setAlertMessage(
                result?.message
                  ? result.message
                  : "Technical error! Contact the Developers Team"
              );
              setOpen(true);
            } else {
              setAlertseverity("success");
              setAlertMessage("user created successfully");
              setOpen(true);
              setLoading(false);
              window.location.href = "/users/teachers";
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
  };
  const subjectChange = (options) => {
    setSubjects(options);
    setValues({
      ...values,
      instructorSubjects: options,
    });
  };
  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "";
    }
  };
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <TabContext value={tab}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {eventPenaltiesCreate ? (
              <TabList
                onChange={handleChangeTab}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Basics" value={1} />
                <Tab label="Salary" value={2} />
                <Tab label="Events" value={3} />
                <Tab label="Incomes" value={4} />
              </TabList>
            ) : (
              <TabList
                onChange={handleChangeTab}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Basics" value={1} />
              </TabList>
            )}
            <div>
            { props.edit && <IconButton
                className={classes.signOutButton}
                color="inherit"
                onClick={switchTeacher}
              >
                <AccountCircleIcon style={{ display: edit ? "" : "none" }} />
                <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                  LOGIN AS USER
                </span>
              </IconButton>}
            </div>
          </div>
          <form autoComplete="off" noValidate>
          <div
              style={{ display: "flex", "justify-content": "space-between" }}
            >
              <CardHeader subheader="The information can be edited" />
            </div>
            <Divider />
            <TabPanel value={1}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="First name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.name || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Last name"
                      name="lastName"
                      onChange={handleChange}
                      required
                      value={values.lastName || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email Address"
                      name="email"
                      onChange={handleChange}
                      required
                      value={values.email || ""}
                      variant="outlined"
                      autoComplete="off"
                      onFocus={onFocus}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phone"
                      onChange={handleChange}
                      type="number"
                      value={values.phone || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="password"
                      name="plainPassword"
                      onChange={handleChange}
                      required
                      value={values.plainPassword || ""}
                      variant="outlined"
                      type="password"
                      autoComplete="off"
                      onFocus={onFocus}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AsyncPaginate
                      loadOptions={getSubjects}
                      value={subjectTeacher}
                      onChange={subjectChange}
                      getOptionLabel={(option) =>
                        option.name + " | " + option?.division?.name
                      }
                      getOptionValue={(option) => option.id}
                      placeholder="Subjects"
                      isMulti
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <FormControl component="fieldset">
                      <FormLabel
                        style={{ fontWeight: "bold" }}
                        component="legend"
                      >
                        Verified
                      </FormLabel>
                      <RadioGroup
                        aria-label="verified"
                        name="verified"
                        onChange={handleChange}
                        value={values?.verified || ""}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="1"
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="0"
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <FormControl component="fieldset">
                      <FormLabel
                        style={{ fontWeight: "bold" }}
                        component="legend"
                      >
                        is AdminTeacher ?
                      </FormLabel>
                      <RadioGroup
                        aria-label="isAdminTeacher"
                        name="isAdminTeacher"
                        onChange={handleChange}
                        value={values?.isAdminTeacher || ""}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="1"
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="0"
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Save details
                </Button>
              </CardActions>
            </TabPanel>

            <TabPanel value={2}>
              <SalaryForm edit={edit} instructorPrices={instructorPrices} />
            </TabPanel>
            <TabPanel value={3}>
              <EventsForm />
            </TabPanel>
            <TabPanel value={4}>
              <IncomesForm />
            </TabPanel>
          </form>
        </TabContext>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <LoadingApi open={loading} onClick={handleCloseLoading} />
    </div>
  );
};

Form.propTypes = {
  // className: PropTypes.string,
  // handleCloseLoading: PropTypes.func,
  // loading: PropTypes.bool,
};

export default Form;
