import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import { api_get, api_post, api_put } from "../../../utils/Api";
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import optionsParser from '../../../helpers/optionsParser';
import Box from '@material-ui/core/Box';
import {DatePicker, LocalizationProvider} from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import {map} from 'underscore';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  buttonPadding: {
    padding: '10px',
  },
}));
type Props = {
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, instructorPrices, ...rest} = props;
  const classes = useStyles();
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertseverity] = useState('');
  const [formState, setFormState] = useState({
    instructorPrices: [
      {
          price: null,
          contentType: null,
          divisions: []
      },
    ],
  });

  useEffect(() => {
    if (edit) {
        setFormState({
            instructorPrices:instructorPrices.length ? instructorPrices :[{
            divisions: [],
            price: null,
            contentType: null,
          },
          ],
        });
    }
  }, []);
  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };
  const handleFieldsChange = (event, index) => {
    const newArr = {...formState};
      newArr.instructorPrices[index][event.target.name] = event.target.value;
      setFormState({...newArr});
  };
  const divisionChange = (value, index) => {
    const newArr = {...formState};
    newArr.instructorPrices[index]['divisions'] = value;
    setFormState({...newArr});
  };

  const contentTypeChange = (value, index) => {
    const newArr = {...formState};
    newArr.instructorPrices[index]['contentType'] = value;
    setFormState({...newArr});
  };

  const AddRemoveSubscription = (operation, index) => {
    if (operation == 'plus') {
      setFormState((previousData) => ({
        ...previousData,
        instructorPrices: formState.instructorPrices.concat({
          divisions: [],
          price: null,
          contentType: null,
        }),
      }));
    } else {
      setFormState((previousData) => ({
        ...previousData,
        instructorPrices: formState.instructorPrices.filter((num, i) => {
          return i != index;
        },
        ),
      }));
    }
  };

  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertseverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const showAlertMessage = (message) => {
    setAlertseverity('error');
    setAlertMessage(message);
    setOpen(true);
  };
  const formatInstructorPrices=(values)=>{
  let newArr={...values};
   newArr.instructorPrices = newArr.instructorPrices.map(contentPrice=>(
      {
        'divisions':contentPrice?.divisions.map(division=>{
          return division.id
        }),
        'contentType': contentPrice?.contentType? contentPrice.contentType.id : contentPrice.content_type.id,
        'price': contentPrice.price,
      }
  ))
    return newArr
  }
  const formValidation = (values) => {   
    return values?.instructorPrices.map((instructorPrice, index) => {
      
      if (!instructorPrice?.divisions || instructorPrice?.divisions?.length === 0){
        return { success: true, message: "Division " + [index+1] + " is missing"  };
      }
      if (instructorPrice?.contentType === null) {
        return { success: true, message: "Content Type " + [index+1] + " is missing" };
      }
      if (!instructorPrice?.price) {
        return { success: true, message: "Price " + [index+1] + " is missing" };
      }
    });
  };
  const handleSubmit = () => {
    const validations = formValidation(formState).filter(
      (validation) => validation?.message && validation.message !== undefined
    );
    if (validations?.length > 0) {
      validations.map((validation) => {
        setAlertMessage(validation?.message);
        setAlertseverity("warning");
        setOpen(true);
      });}
    else{
    let values=formatInstructorPrices(formState);
    if (edit) {
      api_put(`instructors/${id}/update-instructor-price`, values).then((data) => {
        if(data.errors){
          showErrorMessage(data.errors)

        }else if(data?.code !== 200 && data?.code !== 201){
          showAlertMessage(data?.message? data.message:'Technical error! Contact the Developers Team')
        } else {
          setAlertseverity('success');
          setAlertMessage('instructor updated successfully');
          setOpen(true);
          window.location.href ='/users/teachers';
        }
      });
    }
   }
  };

  const getDivisions = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`divisions?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const getContentTypes = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`content-types?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
          <CardContent>
            {
              formState.instructorPrices.map((contentPrice, index) => {
                return (
                  <Grid
                    container
                    spacing={3}
                    key={index}
                  >
                    <Grid
                      item
                      md={4}
                      xs={8}
                    >
                      <AsyncPaginate
                        loadOptions={getDivisions}
                        value={ contentPrice.divisions}
                        isMulti
                        onChange={(v) => divisionChange(v, index)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder="Division"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: '53px',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: '#000',
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector('body')}
                      />
                    </Grid>
                    <Grid item
                      md={3}
                      xs={3}>
                      <AsyncPaginate
                        loadOptions={getContentTypes}
                        value={contentPrice.contentType ? contentPrice.contentType : contentPrice.content_type}
                        onChange={(v) => contentTypeChange(v, index)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder="Content Type"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: '53px',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: '#000',
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector('body')}
                      />
                    </Grid>
                    <Grid item
                      md={1}
                      xs={1}>
                      <TextField
                        fullWidth
                        name="price"
                        variant="outlined"
                        label="Price"
                        value={contentPrice.price}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => handleFieldsChange(e, index)}
                      >
                      </TextField>
                    </Grid>
                    <Grid item
                      md={2}
                      xs={1}>
                      <Box m={1} pt={1} hidden={index != 0}>
                        <Button color="primary" variant="contained"
                          onClick={() => AddRemoveSubscription('plus')}>
                                                        +
                        </Button>
                      </Box>
                      <Box m={1} pt={1} hidden={index == 0}>
                        <Button color="secondary" variant="contained"
                          onClick={(event) => AddRemoveSubscription(event, index)}>
                                                        -
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                );
              },
              )}
          </CardContent>
          <Divider/>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}>
                            Save details
            </Button>
          </CardActions>
      </Card>
      <SnackBar open={open} message={message} severity={severity} handleClose={handleClose}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
};

export default Form;
