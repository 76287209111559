import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Toolbar from '../../components/Toolbar/Toolbar'
import Table from '../../components/Table/Table';
import Collapse from '@material-ui/core/Collapse';
import Columns from '../../components/Columns';
import Filter from '../../components/Filter/Filter';
import optionsParser from '../../helpers/optionsParser';
import Loading from '../../components/Loading/Loading';
import {api_delete, api_get, get_file} from "../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
const columnsList = [
  {accessor: 'id', label: 'ID', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '20%', show: true},
  {accessor: 'phone', label: 'Phone', width: '20%', show: true},
  {accessor: 'email', label: 'Email', width: '20%', show: true},
  {accessor: 'phone', label: 'Phone', width: '20%', show: true},
  {accessor: 'created_at', label: 'CreatedAt', width: '20%', show: true,type:'date'},
  {accessor: 'updated_at', label: 'UpdatedAt', width: '20%', show: true,type:'date'},


  
];
const fieldSearchable=[
  'id',
  'name',
  'email',
  'phone',
];
const fieldFilterable = [
  {name: 'companySubscriptions.divisions.division_id', label: 'Division', type: 'divisionAutocomplete'},
  {name: 'companySubscriptions.user_group_id', label: 'User group', type: 'userGroupAutocomplete'},
];

const BusinessList = () => {
  const [search, searchChange] = useState('');
  const [filters, filtersChange] = useState({});
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({accessor: "id",order: "desc"});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(24);

  useEffect(() => {
    getData();
  }, [page, sort]);

  useEffect(() => {
    if ((search.length || Object.keys(filters).length)) {
      setPage(1);
      getData();
    }
  }, [filters, search]);


  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const getData = (perPage = 24)=>{
    const options = optionsParser(search, filters, sort, fieldSearchable);
    api_get(`business?page=${page}${options}&perPage=${perPage}`).then((data)=>{
      setData(data);
    });
  };

  const getListData = (perPage = 24) => {
    const options = optionsParser(null, null, sort, null);
    api_get(`business?searchJoin=and&page=${page}${options}&perPage=${perPage}`).then((data) => {
      setData(data);
    });
  };

  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    getData(event.target.value)
  };
  const handleExport = () => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    get_file(`business/export?searchJoin=and&page=${page}${options}`).then((data) => {
    })
  }
  const classes = useStyles();
  if (!data.payload) {
    return (<Loading/>);
  }
  return (
      <div className={classes.root}>
        <Toolbar toggleFilters={toggleFilters} toggleColumns={toggleColumns} searchChange={searchChange}
                 pageLink='/users/business'
                 searchMessage={'Search (ID, Name, Email and Phone)'}
                 handleExport={handleExport}/>
        <div className={classes.content}>
          <Collapse in={columnsOpen}>
            <Columns columnsChange={columnsChange} columns={columns}/>
          </Collapse>
          <Collapse in={filtersOpen}>
            <Filter fields={fieldFilterable} values={filters} filtersChange={filtersChange} initializeData={getListData}/>
          </Collapse>
          <Table columns={columns} data={data} handleSortChange={handleSortChange} sort={sort}
                 handlePageChange={handlePageChange} page={page}
                 rowsPerPage={rowsPerPage}
                 handleRowsPerPageChange={handleRowsPerPageChange}
                 tableService={{method: api_delete, base_url: `business`}} 
                 deleteUrl={`delete/many-business`}
                 keyValue={"business"}
                 pageLink='/users/business' 
                 initializeData={getListData}/>
        </div>
      </div>
  );
};

export default BusinessList;
