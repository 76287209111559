import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '3%', show: true, sortable: true},
  {accessor: 'reference', name: 'reference', label: 'Reference', width: '10%', show: true, sortable: true,},
  {accessor: 'amount', name: 'amount', label: 'Amount', width: '5%', show: true, sortable: true, type: 'pointsType'},
  {accessor: 'type', name: 'type', type: 'typeColor', label: 'Payment Type', width: '6%', show: true, sortable: true},
  {accessor: 'user', label: 'User', width: '14%', show: true, type: 'concatString',},
  {accessor: 'parent_user', label: 'ParentUser', width: '10%', show: true, type: 'concatStringParent',},
  {accessor: 'user.phone', label: 'Phone', width: '14%', show: true, type: 'fieldValue',},
  {accessor: 'division.name', label: 'Division', width: '14%', show: true, type: 'fieldValue',},
  {accessor: 'account.name', label: 'Account', width: '10%', show: true, type: 'fieldValue'},
  {accessor: 'office.name', label: 'Office', width: '10%', show: true, type: 'fieldValue'},
  {accessor: 'status', name: 'status', label: 'Status', width: '6%', show: true, sortable: true, type: 'statusColor'},
  {accessor: 'created_at', name: 'createdAt', label: 'Date', width: '13%', type : 'date', show: true, sortable: true,},
  {accessor: 'payment_method.description', label: 'Payment Method', width: '10%', show: true, type: 'fieldValue',},
  {accessor: 'admin_user.name', label: 'Admin', width: '10%', show: true, type: 'fieldValue'},
  {accessor: 'comment', label: 'Comment', width: '15%', show: true, type: 'fieldValue'},
];

const fieldSearchable = [
  //'id',
  'reference',
];
const fieldFilterable = [
  {name: 'id', label: 'Order ID', type: 'text'},
  {name: 'user.id', label: 'User', type: 'userAutocomplete'},
  {name: 'amount', label: 'Amount', type: 'numberRange'},
  //{name: 'amount', label: 'Amount', type: 'number'},
  {name: 'status', label: 'Status', type: 'dropdown', options: ['ignored', 'approuved', 'pending', 'active'],},
  {name: 'type', label: 'Type', type: 'dropdown', options: ['cash', 'check', 'transfer', 'payment', 'poste'],},
  {name: 'paymentMethod.id', label: 'Payment method', type: 'paymentMethodAutocomplete',},
  {name: 'office.id', label: 'Office', type: 'officeAutocomplete'},
  {name: 'account.id', label: 'Account', type: 'accountAutocomplete'},
  {name: 'agency2.id', label: 'Agency', type: 'agencyAutocomplete'},
  {name: 'cashedAt', label: 'Cashed at', type: 'dateRange'},
  {name: 'adminUser.id', label: 'Admin', type: 'adminAutocomplete'},

];
const CodeList = () => {

  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`codes`}
          exportUrl={`codes/export`}
          pageLink ={'/finance/finance-codes'}
          searchMessage={'Search (Reference)'}
          baseUrl={`codes`}
          deleteUrl={`delete/many-codes`}
          keyValue={"codes"}
      />
  );
};

export default CodeList;
