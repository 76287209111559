// @flow
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import SnackBar from '../../../components/SnackBar';
import optionsParser from '../../../helpers/optionsParser';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import {api_get, api_post} from "../../../utils/Api";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LoadingApi from 'components/LoadingApi/LoadingApi';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  style: {
    color: 'red',
    fontSize: 20
  },
 
}));
type Props = {
  edit: boolean,
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [smsReceivers, setSmsReceivers] = useState('');
  const [values, setValues] = useState({
    title: '',
    text: '',
    state : null,
    division: null,
    groups: null,
    user: null,
    segment: null,
    phone: null,
    toNotAffiliated: false,
    withoutLevels: false,
    withoutEmail: false,

  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [smsValid,setSmsValid]=useState({})
  const [confirmValue,setConfirmValue]=useState({})

  // users -----
  const getUsers = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['id', 'name', 'lastName','email', 'phone']);
    const result = await api_get(`users?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };


    // teachers -----
  const getTeachers = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['id', 'name', 'lastName', 'email', 'phone']);
    const result = await api_get(`instructors?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
    // divisions -----
  const getDivisions = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`divisions?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const divisionChange = (options) => {
    setValues({...values, division: options});
  };
    // user group -----
  const getUserGroup = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`groups?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const userGroupChange = (value) => {
    setValues({
      ...values,
      groups: value,
    });
  };
    // states -----
  const getStates = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`1/states?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const stateChange = (value) => {
    setValues({...values, state: value});
  };

  const usersOutsideChange = (event) =>{
    setValues({...values, phone: event.target.value});
  };

  const handleTextChange = (event) => {
    if (event.target.value.length <= 157) {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleCheckChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };
  const getSegment = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`segments?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const segmentChange = (options) => {
    setValues({...values, segment: options});
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key] ? errors[key] : "Technical error! Contact the Developers Team");
        setOpen(true);
      }
    });
  };

  const studentChange = (options) => {
    setStudents(options);

  };
  const teacherChange = (options) => {
    setTeachers(options);

  };
  const formValidation = (values) => {
    if (!values?.title) {
      return { success: false, 'message': 'Title is missing' };
    }
    if (!values?.text) {
      return { success: false, 'message': 'Text is missing' };
    }
    // if ((!values?.division && !values?.groups && !values.state && !values.segment && !values.user && !values.phone) ){
    //   return { success: false, 'message': 'Must choose at least one of those information (division, user group, users, professors, state, segments or external phone)' }
    // }
  };

  const smsFormat = (values) => {
    let divisionArr = [];
    let groupArr= [];
    let stateArr= [];
    let segmentArr = [];
    let userArr = [];

    values.division && values.division.map(option => {divisionArr.push(option.id)});
    values.division = divisionArr;

    values.groups && values.groups.map(option=>{groupArr.push(option.id)})
    values.groups = groupArr;

    values.state && values.state.map(option=>{stateArr.push(option.id)})
    values.state = stateArr;

    values.segment && values.segment.map(option=>{segmentArr.push(option.id)})
    values.segment = segmentArr;

    students && students.map(option=>{userArr.push(option.id)})
    teachers && teachers.map(option=>{userArr.push(option.id)})
    values.user = userArr;
    return values;
  };

  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else{
      const valuesState = smsFormat(JSON.parse(JSON.stringify(values)));
      api_post('sms-users',valuesState)
          .then((result) => {
            setSmsValid({...valuesState})
            setConfirmValue({...values})
            setSmsReceivers(result.payload);
            if (result.errors) {
              showErrorMessage(result.errors);
            } else {
              setOpenModal(!openModal);
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
    }
  };
  const handleSubmitConfirmDialog = () => {
      //const valuesState = smsFormat(values);
      api_post('sms',smsValid)
          .then((result) => {
            if (result.errors) {
              showErrorMessage(result.errors);
            } else {
              setOpenModal(!openModal);
              setAlertSeverity('success');
              setAlertMessage('SMS sent successfully');
              setOpen(true);
              setLoading(false);
              window.location.href = '/marketing/sms';
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
    };
  

  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
  };
  const handleModalClose = () => {
    setOpenModal(!openModal);
  };
  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <CardHeader subheader="SMS form" />
          <Divider/>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md ={6}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  onChange={handleChange}
                  required
                  value={values.title || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md ={6}>
                <TextField
                  fullWidth
                  helperText={`${values.text.length}/157`}
                  label="Text"
                  name="text"
                  onChange={handleTextChange}
                  required
                  value={values.text || ''}
                  variant="outlined"
                  multiline={true}
                  rows={3}
                  rowsMax={3}
                />
              </Grid>
              <Grid item xs={12} md ={6}>
                <AsyncPaginate
                  loadOptions={getUsers}
                  value={students}
                  onChange={studentChange}
                  getOptionLabel={(option) => '[' + option.id + ']' + ' ' +
                          option.name + ' ' + option.last_name +' '+ (option?.phone ? option.phone : '')+' '+ (option?.email ? '['+option?.email+']' : '')}
                      getOptionValue={(option) => option.id}
                  placeholder="Users"
                  isMulti
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: '53px',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#000',
                      fontSize: theme.typography.fontSize,
                      fontFamily: theme.typography.fontFamily,
                    }),
                  }}
                  menuPortalTarget={document.querySelector('body')}
                />
              </Grid>
              <Grid item xs={12} md ={6}>
                <AsyncPaginate
                    loadOptions={getTeachers}
                    value={teachers}
                    onChange={teacherChange}
                    getOptionLabel={(option) => '[' + option.id + ']' + ' ' +
                        option.name + ' ' + option.last_name +' '+ (option?.phone ? option.phone : '')+' '+ (option?.email ? '['+option?.email+']' : '')}
                    getOptionValue={(option) => option.id}
                    placeholder="Professors"
                    isMulti
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: '53px',
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: '#000',
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector('body')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AsyncPaginate
                  loadOptions={getDivisions}
                  value={values.division}
                  onChange={divisionChange}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="Divisions"
                  isMulti
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: '53px',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#000',
                      fontSize: theme.typography.fontSize,
                      fontFamily: theme.typography.fontFamily,
                    }),
                  }}
                  menuPortalTarget={document.querySelector('body')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AsyncPaginate
                  loadOptions={getUserGroup}
                  value={values.groups}
                  onChange={userGroupChange}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="User group"
                  isMulti
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: '53px',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#000',
                      fontSize: theme.typography.fontSize,
                      fontFamily: theme.typography.fontFamily,
                    }),
                  }}
                  menuPortalTarget={document.querySelector('body')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AsyncPaginate
                  loadOptions={getStates}
                  value={values.state}
                  onChange={stateChange}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="States"
                  isMulti
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: '53px',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#000',
                      fontSize: theme.typography.fontSize,
                      fontFamily: theme.typography.fontFamily,
                    }),
                  }}
                  menuPortalTarget={document.querySelector('body')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AsyncPaginate
                    loadOptions={getSegment}
                    value={values.segment}
                    onChange={segmentChange}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Segment"
                    isMulti
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: '53px',
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: '#000',
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector('body')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  
                  fullWidth
                  onChange={usersOutsideChange}
                  placeholder="External phone(s) ( Example of typing many phones : xxxxxxxx ; xxxxxxxx ; ... )"
                  variant="outlined"
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: '53px',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#000',
                      fontSize: theme.typography.fontSize,
                      fontFamily: theme.typography.fontFamily,
                    }),
                  }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={values?.toNotAffiliated}
                          onChange={handleCheckChange}
                          value={values.toNotAffiliated}
                          name="toNotAffiliated"
                          size="small"
                      />
                    }
                    label="Users without offers"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={values.withoutEmail}
                          onChange={handleCheckChange}
                          value={values?.withoutEmail || ''}
                          name="withoutEmail"
                          size="small"
                      />
                    }
                    label="Users without emails"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={values.withoutLevels}
                          onChange={handleCheckChange}
                          value={values?.withoutLevels || ''}
                          name="withoutLevels"
                          size="small"
                      />
                    }
                    label="Users without levels"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider/>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <Dialog
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
           <DialogTitle id="alert-dialog-title">Send SMS(s) Confirmation</DialogTitle>
          <DialogContent>
               <DialogContentText id="alert-dialog-description">
                  Are you sure you want to send <span className={classes.style}> {smsReceivers?.nbUsers} </span> SMS(s) ?
              </DialogContentText>
           </DialogContent>
          <DialogActions>
               <Button onClick={handleModalClose} color="primary">
                  Cancel
              </Button>
              <Button onClick={()=> handleSubmitConfirmDialog()} color="primary" autoFocus>
                  Yes
              </Button>
          </DialogActions>
      </Dialog>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    <LoadingApi open={loading} onClick={handleCloseLoading}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,
};

export default Form;
