import React, {useState, useCallback, useEffect} from 'react';
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FileCopyIcon from "@material-ui/icons/FileCopy";
import bytesToSize from "../utils/bytesToSize";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core'; 
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; 
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; 
import theme from 'theme';
import { red } from '@material-ui/core/colors';
import { current } from '@reduxjs/toolkit';

const useStyles = makeStyles((theme) => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: "pointer",
    },
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  pdfContainer: {
    maxHeight: 620,
    maxWidth: 520,
    marginLeft: theme.spacing(30),
  },
}));

const FilesDropzone = (props) => {
  const {className,setValues,values, onFileChange, onImageChange,isVideo, isImage, isPdf, isMultiple,isattachment, setExams, edit, ...rest} = props;
  const classes = useStyles();
  //const prevFiles = values?.content?.contentFiles[0]?.files ? values?.content?.contentFiles[0]?.files  : [];
  const [files, setFiles] = useState('');
  const [pdfFile, setPdfFile]=useState(null);
  const [selectedPdfFile, setSelectedPdfFile]=useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewPdf, setViewPdf]=useState(null);
  const [hidePdf, setHidePdf]=useState(false);
  const open = Boolean(anchorEl);
  const fileArr = [];

    // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  

  useEffect(() => {
    handlePdfFilePreview();
  }, [pdfFile]);

  const handleDrop = useCallback((acceptedFiles) => {
    //setFiles(prevFiles)
    setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles));
    //onFileChange(acceptedFiles);
  }, []);

  const handlePdfFileChange=(e)=>{
      let fileType = ['application/pdf'];
      setSelectedPdfFile(e);
      setPdfFile(selectedPdfFile);
        if(selectedPdfFile&&fileType.includes(selectedPdfFile.type)){
          let reader = new FileReader();
              reader.readAsDataURL(selectedPdfFile);
              reader.onloadend = (e) =>{
                setPdfFile(e.target.result);
              }
        }
      setPdfFile(null);
    }
  const handlePdfFilePreview=(e)=>{
        if(pdfFile !== null){
          setViewPdf(pdfFile);
          setHidePdf(true);
        }
        else{
          setViewPdf(null);
        }
    }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete =  (file) => {
    setViewPdf(null);
    setSelectedPdfFile(null);
    setPdfFile(null);
    setHidePdf(false);
   /* const newFiles = [...files]
    newFiles.splice(file.indexOf(file), 1)
    setFiles(newFiles)*/
    const newFiles = [...files];     // make a var for the new array
    newFiles.splice(file, 1);        // remove the file from the array
    setFiles(newFiles);

  }
  const onFilesChange = (files) =>{
    onFileChange(files);
    //setFiles([]);
  }
  const handleDeleteUploaded = (file) => {
  /* const newValues = {...values}
    newValues.contentFiles.splice(newValues.contentFiles.indexOf(file), 1)
    setValues({
      ...values,
      contentFiles: newValues.contentFiles,
    });*/
  }

  const handleRemoveAll = () => {
    setSelectedPdfFile(null);
    setPdfFile(null);
    setViewPdf(null);
    setHidePdf(false);
    setFiles([]);
    if(values){
      setValues({
        ...values, content: {
          ...values.content, contentFiles: [],
        }
      });
    }
  };

  const hidePdfPreview = (file) => {
    setPdfFile(null);
    setSelectedPdfFile(null);
    setViewPdf(null);
    setHidePdf(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: isImage ? '.jpeg,.jpg,.png,.gif' : isVideo ? 'video/*' : 'application/pdf',
    multiple: isMultiple ? true : false,
  });
  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt="Select file"
            className={classes.image}
            src="/images/undraw_add_file2_gvbb.svg"
          />
        </div>
        <div>
          <Typography gutterBottom variant="h3">
            Select files
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              Drop files here or click <Link underline="always">browse</Link>{" "}
              thorough your machine
            </Typography >
          </Box>
        </div>
      </div>

      {files && files.length > 0 && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem divider={i < files.length - 1} key={i}>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    style={{overflowWrap: 'break-word'}}
                    primaryTypographyProps={{ variant: "h5" }}
                    secondary={bytesToSize(file.size)}
                  />
                  {isPdf &&
                   <div> 
                   {!hidePdf && 
                      <Tooltip
                          enterDelay={300}
                          title="Double Click to Preview">
                          <Button size="xs"  onClick={() => handlePdfFileChange(file)}>
                            <PictureAsPdfIcon />
                          </Button>
                      </Tooltip>}
                    {hidePdf && 
                      <Tooltip
                          enterDelay={300}
                          title="Hide preview">
                          <Button size="xs"  onClick={() => hidePdfPreview(file)}>
                            <VisibilityOffIcon/>
                          </Button>
                      </Tooltip>}
                  </div>}
                  <div>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            width: '20ch',
                          },
                        }}
                    >
                      <MenuItem onClick={()=> handleDelete(file)}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
        </>
      )}
      <div className={classes.actions}>
        <Button onClick={handleRemoveAll} size="small">
          Remove all
        </Button>
        <Button color="secondary" size="small" variant="contained" onClick={()=> onFilesChange(files)}>
          Upload files
        </Button>
      </div>
      <div  className={classes.pdfContainer} >
        {/* show pdf conditionally (if we have one)  */}
        {viewPdf&&
        <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <div style={{ height: "620px" }}>
              <Viewer fileUrl={viewPdf} plugins={[defaultLayoutPluginInstance]} />
            </div>
          </Worker></>}
      </div>
    </div>
  );
};

FilesDropzone.propTypes = {
  className: PropTypes.string,
  setValues: PropTypes.func,
  values: PropTypes.object,
  onFileChange: PropTypes.func,
};

export default FilesDropzone;
