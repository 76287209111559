// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Box,
  
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import optionsParser from '../../../helpers/optionsParser';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import {api_get, api_put} from "../../../utils/Api";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormHelperText from "@material-ui/core/FormHelperText";
import LoadingApi from 'components/LoadingApi/LoadingApi';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    'overflow': 'visible',
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },

  uploadedFiles: {
    marginTop: 10,
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const modules = {
  toolbar: [
    [{header: [1, 2, false]}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      {list: 'ordered'},
      {list: 'bullet'},
      {indent: '-1'},
      {indent: '+1'},
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const Form = (props) => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();

  const [values, setValues] = useState({
    name: '',
    isValid: false,
    description: '',
    touched: {},
    errors: {},
    video: {},
    pdfs: [],
    active: true,
    is_draft: false,
    type_id: null,
    selectedChapters: [],
    levels: [],
    report_reason: '',
    reviewed: "",
  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      setLoading(true)
      api_get(`video-reports/${id}`).then((result) => {
        setLoading(false);
        const data = result.payload;
        setValues({
          'description':data.description,
          'content' : data.content,
          'user':data.user ,
          'reportReason':data.report_reason ,
          'reviewed':data.reviewed == true ? "1" : "0",
          'priority': data.priority,
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleCheckChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const getReportReason = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`report-reasons?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const reasonChange = (value) => {
    setValues({
      ...values,
      reportReason: value,
    });
  };
  const handleSubmit = () => {
    const requestValues = mapStateToRequest(values, [], ['reviewed']);
    setLoading(true);
    if (edit) {
      api_put(`video-reports/${id}`,requestValues).then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else {
          setAlertSeverity('success');
          setAlertMessage('video updated successfully');
          setOpen(true);
          setLoading(false);
          window.location.href = '/contents/video-reports';
        }
      });
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
    

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Card className={classes.root}>
              <CardContent>
                <TextField
                    fullWidth
                    label="Content"
                    name="content"
                    onChange={handleChange}
                    disabled
                    value={(values.content && values.content.name)|| ''}
                    variant="outlined"
                    size="small"
                />

                <Box mt={2} mb={1}>
                  <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      onChange={handleChange}
                      disabled
                      value={values.description || ''}
                      variant="outlined"
                      size="small"
                  />
                </Box>
                <Box mt={2} mb={1}>
                  <TextField
                      fullWidth
                      label="User"
                      name="user"
                      onChange={handleChange}
                      disabled
                      value={(values.user && (values.user.name+' '+values.user.last_name)) || ''}
                      variant="outlined"
                      size="small"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card className={classes.root}>
              <CardHeader title="Basic" />
              <Divider className={classes.mtop0} />
              <CardContent className={classes.mtop0}>
                <Box mt={0}>
                  <FormControl component='fieldset'>
                    <FormLabel component='legend'>Reviewed</FormLabel>
                    <RadioGroup
                        aria-label='Reviewed'
                        name='reviewed'
                        onChange={handleChange}
                        value={values.reviewed || ""}
                        //error={hasError("reviewed").toString()}
                        row
                    >
                      <FormControlLabel
                          control={<Radio />}
                          value='1'
                          label='Yes'
                      />
                      <FormControlLabel
                          control={<Radio />}
                          value='0'
                          label='No'
                      />
                    </RadioGroup>
                   {/* {hasError("reviewed") && (
                        <FormHelperText>
                          {formState.errors.reviewed[0]}
                        </FormHelperText>
                    )}*/}
                  </FormControl>
                 {/* <FormControlLabel
                      control={
                        <Checkbox
                            checked={values.reviewed}
                            onChange={handleCheckChange}
                            value={values.reviewed}
                            name="reviewed"
                            size="small"
                        />
                      }
                      label="Reviewed "
                  />*/}
                </Box>
                <Box mt={2}>
                <AsyncPaginate
                     isClearable={true}
                    loadOptions={getReportReason}
                    value={values.reportReason}
                    onChange={reasonChange}
                    getOptionLabel={(option) => option.reason}
                    getOptionValue={(option) => option.id}
                    placeholder="Report reason"
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: '53px',
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: '#000',
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector('body')}
                />
                </Box>
                <Box mt={2}>
                  <TextField
                      fullWidth
                      label="Priority"
                      name="priority"
                      onChange={handleChange}
                      required
                      value={values.priority || ''}
                      variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>

          </Grid>
        </Grid>
        <Box mt={3}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Save details
          </Button>
        </Box>
      </form>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <LoadingApi open={loading} onClick={handleCloseLoading}/>
    </div>
  );
};


Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,
};

export default Form;
