import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Toolbar from '../../components/Toolbar/Toolbar'
import Table from '../../components/Table/Table'
import Collapse from '@material-ui/core/Collapse';
import Columns from '../../components/Columns';
import Filter from '../../components/Filter/Filter';
import {api_delete, api_get, get_file} from "../../utils/Api";
import optionsParser from '../../helpers/optionsParser';
import Loading from '../../components/Loading/Loading';
import Moment from "moment";
import {Button} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
const columnsList = [
  {accessor: 'id', name: 'id',label: 'Id', width: '5%', show: true, sortable: true, type:'redirectCalled'},
  {accessor: 'name', label: 'Name', width: '10%', show: true, type: 'fieldValue'},
  {accessor: 'last_name', label: 'LastName', width: '10%', show: true, type: 'fieldValue'},
  {accessor: 'phone', label: 'Phone', width: '10%', show: true, type: 'fieldValue'},
  {accessor: 'email', label: 'Email', width: '10%', show: true, type: 'fieldValue'},
  {accessor: 'division.name', label: 'Division', width: '10%', show: true},
  {accessor: 'affiliations', label: 'affiliations', width: '20%', show: true, type: 'affiliationList',},
  {accessor: 'called_user.called', label: 'Called', width: '5%', show: true, type: 'boolean', color: 'primary'},
  {accessor: 'called_user.note', label: 'Note', width: '30%', show: true, type: 'fieldValue'},
  {accessor: 'called_user.is_confirmed', label: 'Confirmed', width: '5%', show: true, type: 'boolean', color: 'default'},
  {accessor: 'called_user.marketer.username', label: 'Marketer', width: '20%', show: true, type: 'fieldValue'},
  {accessor: 'created_at', label: 'Created At', width: '30%', show: true, type: 'date'},
];
const fieldSearchable=[
    'user.id'
];
const fieldFilterable = [
  {name: 'user.createdAt', label: 'Created at', type: 'dateRange'},
  {name: 'user.division.id', label: 'Division', type: 'divisionAutocomplete'},
  {name: 'affiliations.group.id', label: 'User group', type: 'userGroupAutocomplete',},
  {name: 'marketer.id', label: 'Commercial', type: 'adminAutocomplete',},
  {name: 'called', label: 'Called', type: 'dropdown', options: ['Yes', 'No', 'NotYet'],},
  //{name: 'called', label: 'Called', type: 'boolean'},
  {name: 'calledAt', label: 'Called at', type: 'dateRange'},
  {name: 'withAffiliation', label: 'Subscriptions', type: 'dropdown', options: ['yes', 'no'],},
  //{name: 'withAffiliation', label: 'Subscriptions', type: 'boolean'},
];
const CalledUserList = () => {
  const [search, searchChange] = useState('');
  const [filters, filtersChange] = useState({});
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({accessor: "id",order: "desc"});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(24);

  useEffect(() => {
    getData();
  }, [page, sort, rowsPerPage]);

  useEffect(() => {
    if ((search.length || Object.keys(filters).length)) {
      setPage(1);
      getData();
    }
  }, [filters, search]);

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const getData = ()=>{
    const options = optionsParser(search, filters, sort);
    api_get(`called-users?page=${page}${options}&perPage=${rowsPerPage}`).then((data)=>{
      setData(data);
    });
  };

  const getListData = () => {
    const options = optionsParser(null, null, sort, null);
    api_get(`called-users?page=${page}${options}&perPage=${rowsPerPage}`).then((data) => {
      setData(data);
    });
  };
  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    //getData(event.target.value)
  };
  const handleExport = () => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    get_file(`called-users/export?searchJoin=and&page=${page}${options}`).then((data) => {
    })
  }
  const classes = useStyles();
  if (!data.payload) {
    return (<Loading/>);
  }
  return (
    <div className={classes.root}>
      <Button variant="outlined" color="primary"
              style={{marginRight: 8}}
              href={`/marketing/called-user/yesteday`}
      >
        Yesterday
      </Button>
      <Button variant="outlined"
              color="primary"
              style={{marginRight: 8}}
              href={`/marketing/called-user/today`}
      >
        Today
      </Button>
      <Button variant="outlined"
              color="secondary"
              href={`/marketing/called-user`}
      >
        By Periods
      </Button>
      <Toolbar toggleFilters={toggleFilters} toggleColumns={toggleColumns} searchChange={searchChange}
               pageLink='/marketing/called-user' removeAdd={true} handleExport={handleExport}
               searchMessage={'Search (UserId)'}
      />
      <div className={classes.content}>
        <Collapse in={columnsOpen}>
          <Columns columnsChange={columnsChange} columns={columns}/>
        </Collapse>
        <Collapse in={filtersOpen}>
          <Filter fields={fieldFilterable} values={filters} filtersChange={filtersChange} initializeData={getListData}/>
        </Collapse>

        <Table columns={columns} data={data} handleSortChange={handleSortChange} sort={sort}
               handlePageChange={handlePageChange}
               page={page}
               noShow={true}
               rowsPerPage={rowsPerPage}
               handleRowsPerPageChange={handleRowsPerPageChange}
               tableService={{method: api_delete, base_url: `called-users`}}
               pageLink='/marketing/called-user' noDelete={true} noDeleteMany={true}/>
      </div>
    </div>
  );
};

export default CalledUserList;
