// @flow
import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {api_get, api_post, api_put} from '../../../utils/Api';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import AdminRoleTable from '../../../components/AdminRoleTable/AdminRoleTable';
import {HiddenRoleAdminRoleList} from '../../../utils/constants';
import {formatAdminRoleName} from '../../../utils/acl/aclPages';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
}));
type Props = {
  edit: boolean,
  className: string
}

export const formatAdminRoleList = (roles) => {
  const adminRoles = [];
  roles.map(role => {
    if (!adminRoles.includes(formatAdminRoleName(role)) &&
        !HiddenRoleAdminRoleList.includes(role)) {
      adminRoles.push(formatAdminRoleName(role));
    }
  });
  return adminRoles;
};
const Form = (props: Props): React$Element<any> => {
      const {id} = useParams();
      const {className, edit, ...rest} = props;
      const classes = useStyles();
      const [roles, setRoles] = useState();
      const [roleChanged,setRolesChanged]=useState(false);
      const [values, setValues] = useState({
        'name': '',
        'roles': ['ROLE_ADMIN','ROLE_USER']
      });
      const [page, setPage] = useState(1);
      const handleClose = () => {
        setOpen(false);
      };

      const [message, setAlertMessage] = useState('');
      const [severity, setAlertSeverity] = useState('success');
      const [open, setOpen] = React.useState(false);

      const handlePageChange = (event, page) => {
        setPage(page + 1);
      };
      useEffect(() => {
        api_get(`roles`).then((data) => {
          setRoles(formatAdminRoleList(data.payload));
        });
      }, []);
      useEffect(() => {
        if (edit) {
          api_get(`admin-groups/${id}`).then((result) => {
            const {payload} = result;
            setValues({
              name: payload.name,
              roles: payload.roles,
            });
            setRolesChanged(!roleChanged)
          });
        }
      }, []);
      const handleChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value,
        });
      };
      const showErrorMessage = (errors) => {
        Object.keys(errors).map(function(key, index) {
          if (index == 0) {
            setAlertSeverity('error');
            setAlertMessage(errors[key][0]);
            setOpen(true);
          }
        });
      };
    const formValidation = (values) => {
        if (!values?.name) {
            return { success: false, 'message': 'Name is missing' };
        }
        if (!values?.roles || values.roles?.length <=0) {
            return { success: false, 'message': 'Roles are missing' };
        }
    };
      const handleSubmit = () => {
          const validation = formValidation(values);
          if (validation) {
              setAlertMessage(validation.message);
              setAlertSeverity('warning');
              setOpen(true);
          }
          else {
              const requestValues = mapStateToRequest(values, 'roles');
              if (edit) {
                  api_put(`admin-groups/${id}`, requestValues).then((result) => {
                      if (result.errors) {
                          showErrorMessage(result.errors);
                      } else {
                          setAlertSeverity('success');
                          setAlertMessage('admin role updated successfully');
                          setOpen(true);
                          window.location.href = '/settings/roles';
                      }
                  });
              } else {
                  api_post(`admin-groups`, requestValues).then((result) => {
                      if (result.errors) {
                          showErrorMessage(result.errors);
                      } else {
                          setAlertMessage('admin role created successfully');
                          setOpen(true);
                          window.location.href = '/settings/roles';
                      }
                  }).catch((error) => {
                      throw new Error(error);
                  });
              }
          }
      };

      const handleChangeAdminRole = (role,fromTableRole=false) => {
        let newArr = values.roles;
        if (newArr.indexOf(role) != -1) {
          newArr = newArr.filter(r => r != role);
        } else {
          newArr.push(role);
        }
        setValues(prevState => ({
          ...prevState,
          'roles': newArr,
        }));
        if(fromTableRole) {
          setRolesChanged(!roleChanged)
        }
      };
      const memoAdminRole = useMemo(() => (
           <AdminRoleTable roles={roles}
                          page={page}
                          setPage={setPage}
                          handleClose={handleClose} message={message}
                          severity={severity} open={open}
                          handleChange={handleChangeAdminRole}
                          checkedRoles={values.roles}/>
      ), [roles,roleChanged]);
      return (
          <div>
            <Card
                {...rest}
                className={clsx(classes.root, className)}
            >
              <form
                  autoComplete="off"
                  noValidate
              >
                <CardHeader
                    subheader="AdminRoles form"
                   
                />
                <Divider/>
                <CardContent>
                  <Grid
                      container
                      spacing={3}
                  >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                      <TextField
                          fullWidth
                          label="Name"
                          name="name"
                          onChange={handleChange}
                          required
                          value={values.name || ''}
                          variant="outlined"

                      />
                    </Grid>
                    <Grid
                        item
                        md={2}
                        xs={2}
                    >
                      <FormGroup>
                        <FormControlLabel
                            control={<Switch
                                checked={values.roles.indexOf('ROLE_SUPER_ADMIN') !=
                                -1} onChange={() => handleChangeAdminRole(
                                'ROLE_SUPER_ADMIN')}/>}
                            label="ROLE_SUPER_ADMIN"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid
                        item
                        md={2}
                        xs={2}
                    >
                      <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={values.roles.indexOf(
                                'ROLE_ALLOWED_TO_SWITCH') != -1}
                                             onChange={() => handleChangeAdminRole(
                                                 'ROLE_ALLOWED_TO_SWITCH')}/>}
                            label="ROLE_ALLOWED_TO_SWITCH"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={12}
                    >
                      {memoAdminRole}
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider/>
                <CardActions>
                  <Button
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}>
                    Save details
                  </Button>
                </CardActions>
              </form>
            </Card>
            <SnackBar open={open} message={message} severity={severity}
                      handleClose={handleClose}/>
          </div>
      );
    }
;

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Form;
