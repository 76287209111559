import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  IconButton,
  CardContent
} from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Label from '../Label';
import Moment from 'moment';
import CopyrightIcon from '@material-ui/icons/Copyright';
import Avatar from '@material-ui/core/Avatar';
import {api_get} from "../../utils/Api";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { MenuBook } from '@material-ui/icons';
import ActionDeleteMany from 'components/ActionDeleteMany/ActionDeleteMany';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
    zIndex:0
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  orange: {
    color: 'red',
    //backgroundColor: deepOrange[500],
  },
  purple: {
    color: 'green',
    //backgroundColor: deepPurple[500],
  },
  actions: {
    justifyContent: 'flex-end',
  },
  hidden: {
    display: 'none',
  },
  listItem: {
    padding: 0,
  },
  customTableContainer: {
    overflowX: "initial"
  },
  CardButtonDelete: {
    background: 'white',
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    fontSize: "14px",
    paddingBottom: "0px",
  }
}));

/**
 * Returns an enhanced table
 *
 * options:
 * sortable: true
 * available column types:
 * chip: color[default, primary]
 * boolean: color[default, primary]
 * date: color[default, primary]
 * simpleList: field
 * switch: -soon-
 */
const CustomTable = (props) => {
  const classes = useStyles();
  const {
    data,
    columns,
    sort,
    handleSortChange,
    ActionComponent,
    tableService,
    pageLink,
    initializeData,
    rowsPerPage,
    noShow, noDelete, noEdit, noAction, offerAction, noPagination, noCheck,replace,deleteUrl,noDeleteMany,keyValue
  } = props;
  const [selected, setSelected] = useState([]);

  const handleSelectAll = (event) => {
    let selected;

    if (event.target.checked) {
      selected = data?.payload.map((row) => row.id);
    } else {
      selected = [];
    }

    setSelected(selected);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const accessor = (obj, is, value) => {
    if (typeof is == 'string') {
      return accessor(obj, is.split('.'), value);
    } else if (is.length === 1 && value !== undefined) {
      return obj[is[0]] = value;
    } else if (is.length === 0) {
      return obj;
    } else {
      if (obj[is[0]]) {
        return accessor(obj[is[0]], is.slice(1), value);
      }
      return 'NA';
    }
  };
  const offerDivisionType = (col, value) => {
    if(value && value !== 'NA'){
      return <Chip
          size="small"
          color={value?.offer?.type === "primary" ? 'primary' : 'secondary'}
          label={value?.offer?.name+'|'+value?.division?.name}
      />;
    }
    else{
      return ''
    }

  }
  const domainType = (col, value) => {
    return <Chip
        size="small"
        style={{backgroundColor: value?.name ? 'lightcoral':'forestgreen' , color:'white'}}
        label={value?.name ? value?.name : 'Joker'}
    />;
  }
  const webinarType = (col, value) => {
    return <Chip
        size="small"
        color={value?.email ? 'secondary' : 'primary'}
        label={value?.email ? 'webinar' : 'infos'}
    />;
  }
  const chipType = (col, value) => {
    return <Chip
        size="small"
        color={(value === 'Yes') ? 'secondary' :'primary'}
        label={value}
    />; 
  };
  const chipNumberType = (col, value) => {
    return <Chip
        size="small"
        color={(value !== 0) ? 'secondary' :'primary'}
        label={value}
    />;
  };

  const numberType = (col, value) => {
    const v = Number(value) ? value : 0;
    return chipType(col, v);
  };
  const booleanType = (col, value) => {
    const v = Number(value) ? 'Yes' : 'No';
    return chipType(col, v);
  };  
  const booleanFileType = (col, value) => {
   const v= value.length>0 ? value.length : 0;
    return chipNumberType(col, v);
  };  
  const dateType = (col, value) => {
    const v = moment(value).isValid() ?
        moment(value).format('DD/MM/YYYY').toString() :
        '* * * * *';
    return chipType(col, v);
  };
  const dateTimeType = (col, value) => {
    const v = moment(value).isValid() ?
        moment(value, 'YYYY/MM/DD HH:mm').format('DD/MM/YYYY HH:mm').toString() :
        '* * * * *';
    return chipType(col, v);
  };
  const simpleListType = (col, value) => {
    return <List>
      {value?.map((v) => (
          <ListItem key={v.id}>
            <Chip
                size="small"
                color="secondary"
                label={typeof v === 'string' ? v : v[col.field]}
            />
          </ListItem>
      ))}
    </List>;
  };

  const offerGroup = (col, value) => {
    return <List>
      {
        value && value.name ?
             (
              <Chip
                    size="small"
                    color="secondary"
                    label={value.name}
                />
          ) :(
                <Chip
                    size="small"
                    color="secondary"
                    label="All"
                />
            )
      }

    </List>;
  };

  const typeColor = (col, value) => {
    switch (value) {
      case 'cash':
        return(<Chip label={'cash'} style={{backgroundColor: 'indianred', color:'white'}}/>);
        break;
      case 'poste':
        return(<Chip label={'poste'} style={{backgroundColor: 'mediumvioletred', color:'white'}}/>);
        break;
      case 'transfer':
        return(<Chip label={'transfer'} style={{backgroundColor: 'lightskyblue', color:'white'}}/>);
        break;
      case 'payment':
        return(<Chip label={'payment'} style={{backgroundColor: 'lightpink', color:'white'}}/>);
        break;
      case 'check':
        return(<Chip label={'check'} style={{backgroundColor: 'orangered', color:'white'}}/>);
        break;
      default:
        return '----';
        break;
    }
  }
  const statusColor = (col, value) => {
    switch (value) {
      case 'pending':
          return(<Chip label={value} color="secondary"/>);
          break;
      case 'active':
          return(<Chip  label={value} color="primary"/>);
          break;
      case 'approuved':
        return(<Chip label={'approved'} style={{backgroundColor: 'green', color:'white'}}/>);
          break;
      case 'primary':
        return(<Chip label={'primary'} style={{backgroundColor: 'lightslategrey', color:'white'}}/>);
        break;
      case 'pack':
        return(<Chip label={'pack'} style={{backgroundColor: 'palevioletred', color:'white'}}/>);
        break;
      case 'processed':
        return(<Chip label={'processed'} style={{backgroundColor: 'lightseagreen', color:'white'}}/>);
        break;
      default:
        return(<Chip label={value} style={{backgroundColor: 'red', color:'white'}}/>);
          break;
    }
  }

  const pointsType = (col, value) => {
    if(value === 0 || value === 'NA'){
      return (<Chip label={0} style={{backgroundColor: 'goldenrod', color:'white'}}/>)
    }
    if(0<value && value<= 100){
      return (<Chip label={value} style={{backgroundColor: 'lightseagreen', color:'white'}}/>)
    }
    if(100<value && value<= 1000){
      return (<Chip  label={value} style={{backgroundColor: 'blueviolet', color:'white'}}/>);
    }
    if(value > 1000){
      return ( <Chip label={value} style={{backgroundColor: 'pink', color:'white'}}/>)
    }
  }
  const affilliationSlicesType = (col, value) => {
    return <List>
    {value[0]?.payment_agreement_slices?.map((v) => (
        <ListItem key={v.id}>
          <Chip
              label={v.amount+' DT start from : '+Moment(v.start_date).format('YYYY-MM-DD')+' to '+Moment(v.validity_date).format('YYYY-MM-DD')}
              style={{backgroundColor: v.paid === true ? 'forestgreen' :'lightcoral', color:'white'}}
              icon={v.is_check === true ? <CopyrightIcon/> : null}
          />

        </ListItem>
    ))}
  </List>;
  }
  const slicesListType = (col, value) => {
    return <List>
      {value?.map((v) => (
          <ListItem key={v.id}>
            <Chip
                label={v.amount+' DT start from : '+Moment(v.start_date).format('YYYY-MM-DD')+' to '+Moment(v.validity_date).format('YYYY-MM-DD')}
                style={{backgroundColor: v.paid === true ? 'forestgreen' :'lightcoral', color:'white'}}
                icon={v.is_check === true ? <CopyrightIcon/> : null}
            />

          </ListItem>
      ))}
    </List>;
  };

  const oldAffiliationType = (col, value) => {
    return <List>
          <ListItem key={value.id}>
            <Chip
                label={value.id+' Subscription start from : '+Moment(value.start_date).format('YYYY-MM-DD')+' to '+Moment(value.end_date).format('YYYY-MM-DD')}
                color={value.active === true?"primary" : "secondary"}
                variant="outlined"

            />

          </ListItem>
    </List>;
  };
  const newAffiliationType = (col, value) => {
    return <List>
      <ListItem key={value.id}>
        <Chip
            label={value.id+' Subscription start from : '+Moment(value.start_date).format('YYYY-MM-DD')+' to '+Moment(value.end_date).format('YYYY-MM-DD')}
            style={{backgroundColor: 'lightcoral', color:'white'}}
        />

      </ListItem>
    </List>;
  };

  const redirectCalled = (col, value) => {
    return (
        <label
            color="textPrimary"
        >
          <Tooltip title="Edit">
            <a target="_blank" style={{color:'orangered',textDecoration: 'none', cursor:'pointer'}}
               onClick={() => window.open(`/marketing/called-user/edit/${value}`)}>
              {value}
            </a>
          </Tooltip>
        </label>
    );
  };

  const concatString = (col, value) => {
    return (
        <Typography
            color="textSecondary"
        >
          <Tooltip title="Switch">
          <a target="_blank" style={{color:'royalblue',textDecoration: 'none', cursor:'pointer'}}
             onClick={(event) => switchStudent(event, value?.id)}>
            {value.name} {' '}
            {value.last_name}
            {' '}
          </a>
          </Tooltip>
          <Label>
             Id: {value.id || '---'}
          </Label>
        </Typography>
    );
  };
  const StudentUrl = process.env.REACT_APP_FRONT_STUDENT_URL;
  const switchStudent = (event, id) => {
    api_get(`users/login-as/${id}`).then((data) => {
      window.open(`${StudentUrl}/switch?token=${data?.payload?.token}&userId=${data?.payload?.userId}&adminId=${data?.payload?.adminId}`);
    });
  };


  const concatStringBusiness = (col, value) => {
    return (
        <Typography
            color="textSecondary"
        >
          <Tooltip title="Switch">
          <a target="_blank" style={{color:'royalblue',textDecoration: 'none', cursor:'pointer'}}
             onClick={(event) => switchBusiness(event, value?.id)}>
            {value.name} {' '}
            {value.last_name}
            {' '}
          </a>
          </Tooltip>
          <Label>
             Id: {value.id || '---'}
          </Label>
        </Typography>
    );
  };

  const concatStringParent = (col, value) => {
    return (
        <Typography
            color="textSecondary"
        >
          <Tooltip title="Switch">
          <a target="_blank" style={{color:'royalblue',textDecoration: 'none', cursor:'pointer'}}
             onClick={(event) => switchParent(event, value?.id)}>
            {value.name} {' '}
            {value.last_name}
            {' '}
          </a>
          </Tooltip>
          <Label>
             Id: {value.id || '---'}
          </Label>
        </Typography>
    );
  };


  const userSwitch = (col, value) => {
    return (
        <Typography
            color="textSecondary"
        >
          <Label>
           {value}
          </Label>
        </Typography>
    );
  }


  const TeacherUrl = process.env.REACT_APP_FRONT_TEACHER_URL;
  const switchTeacher = (event, id) => {
    api_get(`users/login-as/${id}`).then((data) => {
      window.open(`${TeacherUrl}/switch?token=${data?.payload?.token}`);
    });
  };

  const ParentUrl = process.env.REACT_APP_FRONT_PARENT_URL;
  const switchParent = (event, id) => {
    api_get(`parent-users/login-as/${id}`).then((data) => {
      window.open(`${ParentUrl}/switch?token=${data.payload?.token}&parentId=${data.payload?.parentId}&adminId=${data.payload?.adminId}`);
    });
  };

  const BusinessUrl = process.env.REACT_APP_FRONT_BUSINESS_URL;
  const switchBusiness = (event, id) => {
    api_get(`business/login-as/${id}`).then((data) => {
      window.open(`${BusinessUrl}/switch?token=${data.payload?.token}&adminId=${data.payload?.adminId}`);
    });
  };

  const instructorSwitch = (col, value) => {
    return (
        <>
          {
            (value !== null && value !== 'NA' )?
                (
                    <Typography
                        color="textSecondary"
                    >
                      <Label>
                        <Tooltip title="Switch">
                          <a target="_blank" style={{textDecoration: 'none'}}
                             //style={{cursor:'pointer'}}
                             onClick={(event) => switchTeacher(event, value?.id)}>{value?.name+' '+value?.last_name+' | '+ value?.phone }</a>
                        </Tooltip>
                      </Label>
                    </Typography>
                ) : (
                    null
                )
          }
        </>
    )

  }

  const thumbnailDisplay = (col, value) => {
    return (
        <>
          {
            (value !== null && value !== 'NA' )?
                (
                  <img src={value} width="30" height="30"/>
                ) : (
                  null
              )
          }
        </>
    )
  };


  const contentLink = (col, value) => {
    const type = [1, 2, 6].includes(value.type.id) ?
        'content-videos' :
        'magazines';
    const itemId = [1, 2, 6].includes(value.type.id) ?
        value?.content_videos[0]?.id :
        value?.content_files[0]?.id;
    return (
        <Typography
            color="textSecondary"
        >
          <Label>
            <a target="_blank" style={{textDecoration: 'none'}}
               href={`/contents/${type}/edit/${itemId}`}>{value.name}</a>
          </Label>
        </Typography>
    );
  };

  const traineeshipLink = (col, value) => {
    return (
        <Typography
            color="textSecondary"
        >
          <Label>
            <a target="_blank" style={{textDecoration: 'none'}}
               href={`/hessaty/traineeships/edit/${value?.id}`}>{value?.name}</a>
          </Label>
        </Typography>
    );
  };
  const groupType = (col, value) => {
    return <List>
      {
        (value === 'NA' || !value || value?.length <= 0 )?
            (
                <label>  </label>
               ) :(
                value.map((v) => (
                    <ListItem key={v.id}>
                      <Chip
                          size="small"
                          color="secondary"
                          //value without column name
                          label={v?.group ? v.group.name : v?.offer?  v.offer.name : v?.division ? v?.division?.name : '----'}
                      />
                    </ListItem>
                ))
            )
      }

    </List>;
  };

  const chapterType = (col, value) => {
    return <List>
      {
        value.length <= 0 ?
            (
                <label>  </label>
            ) :(
                value.map((v) => (
                    <ListItem key={v.id}>
                      <Chip
                          size="small"
                          style={{backgroundColor: 'lightseagreen' , color:'white'}}
                          //color="secondary"
                          label={v.chapter ? (v.chapter?.subject?.division?.name+' | '+ v.chapter?.subject?.name) : '----'}
                      />
                    </ListItem>
                ))
            )
      }

    </List>;
  };

  const subjectType = (col, value) => {
    return (
      <Tooltip
        title={
          <List>
            {value.length <= 0 ? (
              <label> </label>
            ) : (
              value.map((v) => (
                <ListItem key={v.id}>
                  <div style={{ fontSize: "13px", fontWeight: "bold" }}>
                    {v?.chapter
                        ? v.chapter.subject?.division?.name + " | " + v.chapter.subject?.name :
                        v?.subject
                      ? v.subject?.division?.name + " | " + v.subject?.name
                      : "----"}
                  </div>
                </ListItem>
              ))
            )}
          </List>
        }
      style={{color:"black"}}>
        <IconButton>
          <MenuBook />
        </IconButton>
      </Tooltip>
    );
  };
  const targetType = (col, value) => {
    return (
      <Tooltip
        title={
          <List>
            {value.length <= 0 ? (
              <label> No details </label>
            ) : (
              value.map((v) => (
                <ListItem key={v.id}>
                  <div style={{ fontSize: "13px", fontWeight: "bold" }}> 
                  <span style={{color: 'white'}}>{v?.division? `  ${v.division?.name} ` : ''}</span>
                  <span style={{color:'lightcoral'}}>{v?.group? `  ${ v.group?.name }  `  : ''}</span>
                  <span style={{color:'lightseagreen'}}>{v?.subject? `  ${v?.subject?.division?.name+' '+ v?.subject?.name} `  : ''}</span>
                  <span style={{color:'orchid'}}>{v?.student_level? `  ${v.student_level?.name} `    : ''}</span>
                  </div>
                </ListItem>
              ))
            )}
          </List>
        }
      style={{color:"black"}}>
        <IconButton>
          <MenuBook />
        </IconButton>
      </Tooltip>
    );
  };

  const affiliationList = (col, value) => {
    return <List>
      {
        (value === 'NA' || !value || value?.length <= 0 )?
            (
                <label>  </label>
            ) :(
                value.map((v) => {
                    return  (
                        ((Moment(v?.end_date).format('YYYY-MM-DD HH:mm:ss') >=Moment().format('YYYY-MM-DD HH:mm:ss'))) && v?.active === true &&
                        (
                            <ListItem key={v.id}>
                              <Chip
                                  //size="small"
                                  variant="outlined"
                                  color={(Moment(v.end_date).diff(Moment().format('YYYY-MM-DD'), 'days')>=7)?"primary" : "secondary"}
                                  avatar={v.end_date ? <Avatar >{Moment(v.end_date).diff(Moment().format('YYYY-MM-DD'), 'days')}</Avatar > : ''}
                                  label={v.group ? v.group.name+(v?.offer_division_subject?  ' | '+v?.offer_division_subject?.subject?.name: '' ): '----'}
                              />
                            </ListItem>
                        ))}
                )
            )
      }

    </List>;
  };

  const fieldValue = (col, value) => {
    return value !== null && value !== 'NA' ? value : ' '
  }
    const levels = (col, value) => {
    return <List>
      {
        value.length <= 0 ?
            (
                <label>  </label>

            ) :(
                value.map((v) => (
                    <ListItem key={v.id}>
                      <Chip
                          size="small"
                          color="secondary"
                          //value without column name
                          label={v.level ? v.level.name : '----'}
                      />
                    </ListItem>
                ))
            )
      }

    </List>;
  };

  const studentLevels = (col, value) => {
    return <List>
      {
        value.length <= 0 ?
            (
                <label>  </label>
            ) :(
                value.map((v) => (
                    <ListItem key={v.id}>
                      <Chip
                          size="small"
                          color="secondary"
                          label={v.student_level ? v.student_level.name : '----'}
                      />
                    </ListItem>
                ))
            )
      }

    </List>;
  };

  const concatSubject = (col, value) => {
    return <List>
      {
        value.length <= 0 ?
            (
                <label> </label>

            ) :(
                value.map((v) => (
                    <ListItem key={v.id}>
                      <Chip
                          size="small"
                          color="primary"
                          //value without column name
                          label={v.subject && v.subject.division.name+ ' |'+v.subject.name}
                      />
                    </ListItem>
                ))
            )
      }

    </List>;
  };
  const renderType = (item, col) => {
    const value = accessor(item, col.accessor);
    switch (col.type) {
      case 'chip':
        return chipType(col, value);
        case 'File':
          return booleanFileType(col, value)
      case 'boolean':
        return booleanType(col, value);
      case 'date':
        return dateType(col, value);
      case 'dateTime':
        return dateTimeType(col,value)
      case 'simpleList':
        return simpleListType(col, value);
      case 'concatString':
        return concatString(col, value);
      case 'concatStringParent':
        return concatStringParent(col, value);
        case 'concatStringBusiness':
        return concatStringBusiness(col, value);
      case 'contentLink' :
        return contentLink(col, value);
      case 'offerGroup' :
        return offerGroup(col, value);
      case 'groupType' :
        return groupType(col, value);
      case 'levels' :
        return levels(col, value);
      case 'concatSubject' :
        return concatSubject(col, value);
      case 'thumbnailDisplay' :
        return thumbnailDisplay(col, value);
        case 'statusColor' :
          return statusColor(col, value);
      case 'affiliationList' :
        return affiliationList(col, value);
      case 'slicesListType' :
        return slicesListType(col, value);
      case 'oldAffiliationType' :
        return oldAffiliationType(col, value);
      case 'newAffiliationType' :
        return newAffiliationType(col, value);
      case 'webinarType' :
        return webinarType(col, value);
      case 'fieldValue' :
        return fieldValue(col, value);
      case 'pointsType' :
        return pointsType(col, value);
      case 'offerDivisionType' :
        return offerDivisionType(col, value);
      case 'traineeshipLink' :
        return traineeshipLink(col, value);
      case 'instructorSwitch' :
        return instructorSwitch(col, value);
      case 'userSwitch' :
        return userSwitch(col, value);
      case 'domainType' :
        return domainType(col, value);
      case 'typeColor' :
        return typeColor(col, value);
      case 'studentLevels' :
        return studentLevels(col, value);
      case 'redirectCalled' :
        return redirectCalled(col, value);
      case 'chapterType' :
        return chapterType(col, value);
      case "subjectType":
        return subjectType(col, value);
      case "affilliationSlicesType":
        return affilliationSlicesType(col, value);
      case "targetType":
        return targetType(col, value);
      case "numberType":
        return numberType(col, value);
      default:
        return value;
    }
  };

  return (
    <div>
      <div style={{position:'relative',zIndex:'0'}} className={classes.root}>
          <TableContainer style={{maxHeight:'700px' }}>
        <Table  stickyHeader aria-label="sticky table"  >
          <TableHead  > 
            <TableRow >
              {!noCheck &&
                <TableCell padding="checkbox" >
                    <Checkbox
                        checked={selected.length === data?.payload?.length}
                        color="primary"
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < data?.payload?.length
                        }
                        onChange={handleSelectAll}
                    />
                </TableCell>
              }
              {columns.map((col) => (
                  col.show && <TableCell key={col.accessor}>
                    {col.sortable ? (
                        <TableSortLabel
                            active={sort.name === col.name}
                            direction={sort.name === col.name ?
                                sort.order : 'asc'}
                            onClick={() => handleSortChange(col.name)}
                        >
                          {col.label}
                        </TableSortLabel>) : (
                        <span>{col.label}</span>
                    )}
                  </TableCell>
              ))}
              {!noAction && ActionComponent && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data?.payload?.map((item) => (
                <TableRow
                    hover
                    key={item.id}
                >
                  {
                    !noCheck &&  <TableCell padding="checkbox">
                      <Checkbox
                          checked={selected.indexOf(item.id) !== -1}
                          color="primary"
                          onChange={(event) => handleSelectOne(event, item.id)}
                          value="true"
                      />
                    
                    </TableCell>
                  }
                  {columns.map((col) => (
                      col.show &&
                      <TableCell
                          key={`${col.accessor}-${item.id}`}
                          style={{width: col.width}}
                      >
                        {renderType(item, col)}
                      </TableCell>

                  ))}
                    {!noAction && ActionComponent && <TableCell style={{width: '6%'}}>
                      <div style={{'display': 'flex', 'justifyContent' : 'space-between', 'alignItems': 'center'}}>
                        {(pageLink == "/users/students" || pageLink == "/users/teachers" || pageLink == "/users/parent-users" || pageLink =="/users/business" )  &&
                      <VisibilityIcon style={{'cursor': 'pointer'}} onClick={(event) =>
                        pageLink =="/users/students" 
                        ? switchStudent(event, item?.id) 
                        : pageLink =="/users/teachers" 
                        ? switchTeacher(event, item?.id) :pageLink =="/users/business" ?switchBusiness(event, item?.id)
                        : switchParent(event, item?.id)}/>}
                      <ActionComponent
                                    //itemId={(pageLink == "/hessaty/events" || pageLink =="/contents/events" )? item.content ? item.content.id : item.id : item.id}
                                    item={item}
                                    itemId={item.id}
                                    tableService={tableService}
                                    pageLink={pageLink}
                                    noShow={noShow}
                                    noDelete={noDelete}
                                    noPagination={noPagination}
                                    noCheck={noCheck}
                                    replace={replace}
                                    noEdit={noEdit}
                                    offerAction={offerAction}
                                    initializeData={initializeData}
                                    rowsPerPage={rowsPerPage}
                    />
                      </div>
                  </TableCell>}
                </TableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer > 
 
      </div>
      <div>
        <CardContent className={classes.CardButtonDelete}>
          <ActionDeleteMany
              selectedItems={selected}
              setSelectedItems={setSelected}
              initializeData={initializeData}
              deleteUrl={deleteUrl}
              keyValue={keyValue}
              noDeleteMany={noDeleteMany}
          /> 
        </CardContent>
      </div>
    </div>
  );
};

CustomTable.propTypes = {
  data: PropTypes.object,
  columns: PropTypes.array,
  sort: PropTypes.object,
  handleSortChange: PropTypes.func,
  ActionComponent: PropTypes.elementType,
};

export default CustomTable;
