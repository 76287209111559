// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {CardContent, CardHeader, Divider, Grid, TextField,   Card,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {api_get} from '../../../../utils/Api';
import theme from '../../../../theme';
import {AsyncPaginate} from 'react-select-async-paginate';
import optionsParser from '../../../../helpers/optionsParser';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    overflow: 'visible',
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));

const Expression = (props) => {
  const {
    className,
    edit,
    handleExpressionChange,
    handleAttributeChange,
    handleOperatorChange,
    expression,
    exp,
    expOp,
    expressions,
    handleChangeValue,
    expressionIndex,
      setIndex,
    ...rest
  } = props;
  const classes = useStyles();
  let attribute = expressions[`attribute-condition-c-${exp}`];
  let type = expressions[`operator-condition-c-${exp}`];
  let expressionOperator = expressions[`expression-operator-${expressionIndex}`];
  const [scopes, setScopes] = useState([]);
  const [types, setTypes] = useState([]);
  const [divisions, setDivisions] = useState([]);

  useEffect(() => {
    api_get(`content-scopes?&perPage=100`).then((data)=>{
      setScopes(data.payload);
    });
    api_get(`content-types`).then((data)=>{
      setTypes(data.payload);
    });
    api_get(`divisions?&perPage=100`).then((data)=>{
      setDivisions(data.payload);
    });
  }, []);

  return (
      <div {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Grid
              container
              spacing={3}
          >
            <Button  color="primary" href="#contained-buttons">
              {exp}
            </Button>
            <Grid
                item
                md={2}
                xs={12}
            >
              {expressions['expression-type-e0'] === 'simple' &&
              <FormControl variant="outlined" fullWidth
                           size="small">
                <TextField
                    fullWidth
                    size='small'
                    label="Expression"
                    required
                    disabled
                    value={'simple'}
                    variant="outlined"
                />
              </FormControl>
              }
              { exp !== 'e0' && expressions['expression-type-e0'] === 'combined' &&
                <FormControl variant="outlined" fullWidth
                             size="small">
                  <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={expression}
                      onChange={handleExpressionChange}
                      //label="Expression"
                      name={`expression-type-${exp}`}
                  >
                    <MenuItem value="simple">Simple
                      Expression</MenuItem>
                    <MenuItem value="combined">Combined
                      Expression</MenuItem>
                  </Select>

                </FormControl>
              }
            </Grid>
            {
                expression === 'simple' &&
                    <>
                      <Grid
                          item
                          md={3}
                          xs={12}
                      >
                        <FormControl variant="outlined" fullWidth size="small">
                          <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={attribute}
                              onChange={handleAttributeChange}
                              //label="Expression"
                              name={`attribute-condition-c-${exp}`}
                          >
                            <MenuItem value="id">ID</MenuItem>
                            <MenuItem value="scope">Scope</MenuItem>
                            <MenuItem value="type">Type</MenuItem>
                            <MenuItem value="name">Name</MenuItem>
                            <MenuItem value="division">Division</MenuItem>
                            <MenuItem value="domain">Domain</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                          item
                          md={3}
                          xs={12}
                      >
                        <FormControl variant="outlined" fullWidth size="small">
                          <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              isMulti
                              value={type}
                              onChange={handleOperatorChange}
                              name={`operator-condition-c-${exp}`}
                          >
                            <MenuItem value="eq">Equal To</MenuItem>
                            <MenuItem value="different">Different From</MenuItem>
                            {['type','division','scope'].includes(expressions[`attribute-condition-c-${exp}`]) &&
                            <MenuItem value="in">In</MenuItem>
                            }
                          </Select>
                        </FormControl>

                      </Grid>
                      <Grid
                          item
                          md={3}
                          xs={12}
                      >
                        {expressions[`attribute-condition-c-${exp}`] === 'division' &&
                        <FormControl variant="outlined" fullWidth size="small">
                          <Select
                              labelId="demo-mutiple-name-label"
                              id="demo-mutiple-name"
                              small
                              select
                              multiple={expressions[`operator-condition-c-${exp}`] === 'in'}
                              name={`value-condition-c-${exp}`}
                              variant="outlined"
                              value={expressions[`value-condition-c-${exp}`] || []}
                              onChange={(event) => handleChangeValue(event.target.value, event.target)}
                              //MenuProps={MenuProps}
                          >
                            {
                              divisions.map((division)=>{
                                return (
                                    <MenuItem value={division.id}>{division.name}</MenuItem>);
                              })
                            }
                          </Select>
                        </FormControl>
                       /* <AsyncPaginate
                            loadOptions={getDivisions}
                            isMulti={expressions[`operator-condition-c-${exp}`] === 'in'}
                            value={expressions[`value-condition-c-${exp}-values`]}
                            onChange={handleChangeValue}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            placeholder="Classe"
                            cacheUniqs={[expressions[`value-condition-c-${exp}`]]}
                            noOptionsMessage={() => 'You may choose a division before'}
                            name={`value-condition-c-${exp}`}
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              control: (base) => ({
                                ...base,
                                height: '30px',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#000',
                                fontSize: theme.typography.fontSize,
                                fontFamily: theme.typography.fontFamily,
                              }),
                            }}
                            menuPortalTarget={document.querySelector('body')}
                        />*/
                      }
                      {expressions[`attribute-condition-c-${exp}`] === 'type' &&
                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                            labelId="demo-mutiple-name-label"
                            id="demo-mutiple-name"
                            small
                            select
                            multiple={expressions[`operator-condition-c-${exp}`] === 'in'}
                            name={`value-condition-c-${exp}`}
                            variant="outlined"
                            value={expressions[`value-condition-c-${exp}`] ||[]}
                            onChange={(event) => handleChangeValue(event.target.value, event.target)}
                        >
                          {
                            types.map((type)=>{
                              return (
                                  <MenuItem value={type.id}>{type.name}</MenuItem>);
                            })
                          }
                        </Select>
                      </FormControl>
                      }
                      {expressions[`attribute-condition-c-${exp}`] === 'scope' &&
                      <FormControl variant="outlined" fullWidth size="small">
                      <Select
                          labelId="demo-mutiple-name-label"
                          id="demo-mutiple-name"
                          small
                          select
                          multiple={expressions[`operator-condition-c-${exp}`] === 'in'}
                          name={`value-condition-c-${exp}`}
                          variant="outlined"
                          value={expressions[`value-condition-c-${exp}`] || []}
                          onChange={(event) => handleChangeValue(event.target.value, event.target)}
                          //MenuProps={MenuProps}
                      >
                        {
                          scopes.map((scope)=>{
                            return (
                                <MenuItem value={scope.id}>{scope.name}</MenuItem>);
                          })
                        }
                      </Select>
                      </FormControl>
                      }
                      {!['type','division','scope'].includes(expressions[`attribute-condition-c-${exp}`]) &&
                      <TextField
                          fullWidth
                          label=""
                          name={`value-condition-c-${exp}`}
                          required
                          variant="outlined"
                          size="small"
                          onChange={handleChangeValue}
                      />
                      }
                    </Grid>
                    {
                      exp.includes('-1') &&
                      <FormControl variant="outlined" fullWidth style={{marginLeft: '80px', marginRight: '80px', display: 'flex'}}
                                   size="small">
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={expressionOperator}
                            onChange={handleOperatorChange}
                            name={`expression-operator-${expressionIndex}`}
                        >
                          <MenuItem value="or">OU</MenuItem>
                          <MenuItem value="and">ET</MenuItem>
                        </Select>

                      </FormControl>
                    }
                  </>
            }

          </Grid>
        </CardContent>
      </div>
  );
};

Expression.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Expression;


