import React from 'react';



export const adminRoleActions=[
    "LIST",
    "EDIT",
    "CREATE",
    "VIEW",
    "DELETE",
    "EXPORT",
    "ARCHIVE",
    "UNARCHIVE"
]

export const HiddenRoleAdminRoleList=[
    "ROLE_SUPER_ADMIN","ROLE_ADMIN","ROLE_ALLOWED_TO_SWITCH","ROLE_USER"
]



export const months = [
    { name: 'Juillet', id: 'July', monthNumber: 7 },
    { name: 'Août', id: 'August', monthNumber: 8 },
    { name: 'Septembre', id: 'September', monthNumber: 9 },
    { name: 'Octobre', id: 'October', monthNumber: 10 },
    { name: 'Novembre', id: 'November', monthNumber: 11 },
    { name: 'Décembre', id: 'December', monthNumber: 12 },
    { name: 'Janvier', id: 'January', monthNumber: 1 },
    { name: 'Février', id: 'February', monthNumber: 2 },
    { name: 'Mars', id: 'March', monthNumber: 3 },
    { name: 'Avril', id: 'April', monthNumber: 4 },
    { name: 'Mai', id: 'May', monthNumber: 5 },
    { name: 'Juin', id: 'June', monthNumber: 6 },
  ];

  export const headersRowMonthIncomes = [
    { title: 'Article', align: 'left' },
    { title: 'Quantité', align: 'left' },
    { title: 'Prix unitaire', align: 'left' },
    { title: 'Défaut', align: 'left' },
    { title: 'Prix ', align: 'left' },
    { title: '', align: 'right' },
  ];

  export const headersRowMonthDetailsDirect = [
    { title: 'Date', align: 'left' },
    { title: 'Titre', align: 'left' },
    { title: 'Offres', align: 'left' },
    { title: 'Défaut', align: 'left' },
    { title: 'Prix unitaire', align: 'left' },
    { title: 'Prix total', align: 'left' },
    { title: '', align: 'right' },
  ];

  export const initialNumbersCurrentMonth = [
    {
      title: 'Séances en direct',
      'id': 'liveSessions',
      'value': 0,
      'unit': '',
    },
    {
      title: 'Videos Silver',
      'id': 'videoSilver',
      'value': '0/mn',
      'unit': '/mn',
    },
    {
      title: 'Magazines',
      'id': 'magazine',
      'value': 0,
      'unit': '',
    },
    {
      title: 'Qcm',
      'id': 'qcm',
      'value': 0,
      'unit': '',
    },
    {
      title: 'Youtube',
      'id': 'youtube',
      'value': 0,
      'unit': '',
    },
  ];

 