// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Box, CardMedia,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { api_get, api_post } from "../../../utils/Api";
import {Link, useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import MenuItem from '@material-ui/core/MenuItem';
import {DatePicker, LocalizationProvider} from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SegmentPreview from "./SegmentPreview";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  button :{
    margin: '10px'
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
}));
type Props = {
  edit: boolean,
  className: string
}

const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const [accounts, getAccounts] = useState([]);
  const [importedCodes, getCodes] = useState([]);
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('success');
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [divisions, setDivisions] = useState([]);
  const [states, setStates] = useState([]);
  const [offers, setOffers] = useState([]);
  const [segmentInfos, setInfo] = useState([]);
  const [preview, setPreview] = useState(false);
  const classes = useStyles();
  const [values, setValues] = useState<values>({
    name: '',
    segmentFilters: [
      {
        name: 'phone',
        operator: '',
        value: '',
      },
    ],
  });
  useEffect(() => {
    api_get(`divisions?&perPage=100`).then((data)=>{
      setDivisions(data.payload);
    });
    api_get(`states?&perPage=100`).then((data)=>{
      setStates(data.payload);
    });
    api_get(`groups?&perPage=999`).then((data)=>{
      setOffers(data.payload);
    });
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleModalOpen = () => {
    setOpenModal(!openModal);
  };
  const showErrorMessage=(errors)=>{
    Object.keys(errors).map(function(key, index) {
      if (index==0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const handlePreview = (perPage = 12) => {
    api_post(`segments/preview?perPage=${perPage}`, values).then((result) => {
      setInfo(result)
      setPreview(true)
    }).catch((error) => {
      throw new Error(error);
    });
    }
    const formValidation = (values) => {
    if (!values?.name) {
      return { success: false, 'message': 'Name is missing' };
    }
  };
  const handleSubmit = () => {
    if(!values?.name){
      setAlertSeverity('warning');
      setAlertMessage('Name is missing');
      setOpen(true);
    }
    else {
      api_post(`segments`, values).then((result) => {
        if (result.errors) {
          setAlertSeverity('error');
          showErrorMessage(result.errors);
        } else {
          setAlertSeverity('success');
          setAlertMessage('code created successfully');
          setOpen(true);
          //window.location.href = '/user-settings/segments';
        }
      }).catch((error) => {
        throw new Error(error);
      });
    }
  };

  const AddRemoveCode=(operation, index)=> {
    if (operation == 'plus') {
      setValues((previousData) =>({
        ...previousData,
        segmentFilters: values.segmentFilters.concat({
          name: 'phone',
          operator: '',
          value: '',
        }),
      }));
    } else {
      setValues((previousData) =>({
        ...previousData,
        segmentFilters: values.segmentFilters.filter((num, i)=> {
              return i!=index;
            },
        ),
      }));
    }
  };

  const handleFiledsChange = (event, index) => {
    const newArr = {...values};
    newArr.segmentFilters[index][event.target.name]=event.target.value;
    setValues({...newArr});
  };

  const handleDateChange = (value, index) => {
    const newArr = {...values};
    newArr.segmentFilters[index]['value']= moment(value, 'YYYY/MM/DD').format("YYYY-MM-DD");
    setValues({...newArr});
  }

  return (
      <div>
        <form
            autoComplete="off"
            noValidate
        >
          <Card className={classes.root}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <TextField
                      //autoFocus
                      margin="dense"
                      name="name"
                      label="Name"
                      variant="outlined"
                      value={values.name || ''}
                      onChange={handleChange}
                      fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider/>
          </Card>
          <Box mt={3}>
            <Card
                {...rest}
                className={clsx(classes.root, className)}
            >
              <CardHeader
                  subheader="Segment filters form"
              />
              <Divider/>
              <CardContent>
                {
                  values.segmentFilters && values.segmentFilters.map((segment, index)=> {
                        return (
                            <Grid
                                container
                                spacing={3}
                                key={index}
                            >
                              <p>
                              </p>
                              <Grid item
                                    md={3}
                                    xs={4}>
                                <TextField
                                    fullWidth
                                    select
                                    name="name"
                                    id="name"
                                    variant="outlined"
                                    label="name"
                                    value={segment['name']}
                                    onChange={(e)=>handleFiledsChange(e, index)}
                                >
                                  <MenuItem value="phone">Phone</MenuItem>
                                  <MenuItem value="email">Email</MenuItem>
                                  <MenuItem value="name">Name</MenuItem>
                                  <MenuItem value="division">Division</MenuItem>
                                  <MenuItem value="affiliations.group.id">Group</MenuItem>
                                  <MenuItem value="state">State</MenuItem>
                                  <MenuItem value="createdAt">Created at</MenuItem>
                                </TextField>
                              </Grid>
                              <Grid item
                                    md={3}
                                    xs={4}>
                                <TextField
                                    select
                                    fullWidth
                                    name="operator"
                                    variant="outlined"
                                    label="operator"
                                    value={segment['operator']}
                                    onChange={(e)=>handleFiledsChange(e, index)}
                                >
                                  {['createdAt'].includes(segment['name']) &&
                                  <MenuItem value="date is">is</MenuItem>
                                  }
                                  {!['affiliations.group.id', 'division', 'createdAt'].includes(segment['name']) &&
                                  <MenuItem value="is">is</MenuItem>
                                  }
                                  {!['affiliations.group.id', 'division', 'createdAt'].includes(segment['name']) &&
                                  <MenuItem value="is not">is not</MenuItem>
                                  }
                                  {['division'].includes(segment['name']) &&
                                  <MenuItem value="in">in</MenuItem>
                                  }
                                  {['division'].includes(segment['name']) &&
                                  <MenuItem key="not in" value="not in">not in</MenuItem>
                                  }
                                  {['name', 'phone', 'email'].includes(segment['name']) &&
                                  <MenuItem key="contains" value="contains">contains</MenuItem>
                                  }
                                  {['name', 'phone', 'email'].includes(segment['name']) &&
                                  <MenuItem value="does not contains">does not contains</MenuItem>
                                  }
                                  {['name', 'phone', 'email'].includes(segment['name']) &&
                                  <MenuItem value="starts with">starts with</MenuItem>
                                  }
                                  {['name', 'phone', 'email'].includes(segment['name']) &&
                                  <MenuItem value="ends with">ends with</MenuItem>
                                  }
                                  {['name', 'phone', 'email'].includes(segment['name']) &&
                                  <MenuItem value="is grater than">is grater than</MenuItem>
                                  }
                                  {['name', 'phone', 'email'].includes(segment['name']) &&
                                  <MenuItem value="is less than">is less than</MenuItem>
                                  }
                                  {['name', 'phone', 'email'].includes(segment['name']) &&
                                  <MenuItem value="is greater than or equal">is greater than or equal</MenuItem>
                                  }
                                  {['name', 'phone', 'email'].includes(segment['name']) &&
                                  <MenuItem value="is less than or equal">is less than or equal</MenuItem>
                                  }
                                  <MenuItem value="is empty">is empty</MenuItem>
                                  <MenuItem value="is not empty">is not empty</MenuItem>
                                  {['affiliations.group.id'].includes(segment['name']) &&
                                  <MenuItem key="deep join in" value="deep join in">in</MenuItem>
                                  }
                                 {/* {['affiliations.group.id'].includes(segment['name']) &&
                                  <MenuItem key="deep join is" value="deep join is">is</MenuItem>
                                  }*/}
                                  {['affiliations.group.id'].includes(segment['name']) &&
                                  <MenuItem key="deep join not in" value="deep join not in">not in</MenuItem>
                                  }
                                </TextField>
                              </Grid>
                              {segment['name'] === 'division' && !['is empty', 'is not empty'].includes(segment['operator']) &&
                              <Grid item
                                    md={3}
                                    xs={4}>
                                <FormControl variant="outlined" fullWidth >
                                  <Select
                                      labelId="demo-mutiple-name-label"
                                      id="demo-mutiple-name"
                                      select
                                      multiple
                                      name="value"
                                      variant="outlined"
                                      value={segment['value'] || []}
                                      onChange={(e) => handleFiledsChange(e, index)}
                                      //MenuProps={MenuProps}
                                  >
                                    {
                                      divisions.map((division)=>{
                                        return (
                                            <MenuItem value={division.id}>{division.name}</MenuItem>);
                                      })
                                    }
                                  </Select>
                                </FormControl>
                              </Grid>
                              }
                              {segment['name'] === 'state' && !['is empty', 'is not empty'].includes(segment['operator']) &&
                              <Grid item
                                    md={3}
                                    xs={4}>
                                <TextField
                                    fullWidth
                                    select
                                    name="value"
                                    variant="outlined"
                                    label="Value"
                                    // defaultValue="payment"
                                    value={segment['value'] || []}
                                    onChange={(e) => handleFiledsChange(e, index)}
                                >
                                  {
                                    states.map((state)=>{
                                      return (
                                          <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>);
                                    })
                                  }
                                </TextField>
                              </Grid>
                              }
                              {segment['name'] === 'affiliations.group.id' && !['is empty', 'is not empty'].includes(segment['operator']) &&
                              <Grid item
                                    md={3}
                                    xs={4}>
                                <FormControl variant="outlined" fullWidth >
                                  <Select
                                      labelId="demo-mutiple-name-label"
                                      id="demo-mutiple-name"
                                      select
                                      multiple
                                      name="value"
                                      variant="outlined"
                                      value={segment['value'] || []}
                                      onChange={(e) => handleFiledsChange(e, index)}
                                      //MenuProps={MenuProps}
                                  >
                                    {
                                      offers.map((offer)=>{
                                        return (
                                            <MenuItem value={offer.id}>{offer.name}</MenuItem>);
                                      })
                                    }
                                  </Select>
                                </FormControl>
                              </Grid>
                              }
                              {segment['name'] === 'createdAt' && !['is empty', 'is not empty'].includes(segment['operator']) &&
                              <Grid item
                                    md={3}
                                    xs={4}>
                                <LocalizationProvider dateAdapter={momentAdapter}>
                                  <DatePicker
                                      label="Created at"
                                      name="value"
                                      inputVariant="outlined"
                                      value={segment['value'] || []}
                                      onChange={(value) => handleDateChange(value, index)}
                                      renderInput={(props) => <TextField
                                          {...props}
                                          fullWidth
                                          variant="outlined"
                                      />}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              }
                              {['name','email','phone'].includes(segment['name'])&& !['is empty', 'is not empty'].includes(segment['operator']) &&
                              <Grid item
                                    md={3}
                                    xs={4}>
                                <TextField
                                    fullWidth
                                    name="value"
                                    variant="outlined"
                                    label="Value"
                                    // defaultValue="payment"
                                    value={segment['value']}
                                    onChange={(e) => handleFiledsChange(e, index)}
                                >
                                </TextField>
                              </Grid>
                              }
                              <Grid item
                                    md={3}
                                    xs={4}>
                                <Box m={1} pt={1} hidden={index !=0}>
                                  <Button color="primary" variant="contained" onClick={()=>AddRemoveCode('plus')}>
                                    +
                                  </Button>
                                </Box>
                                <Box m={1} pt={1} hidden={index ==0}>
                                  <Button color="secondary" variant="contained" onClick={ (event)=>AddRemoveCode(event, index)}>
                                    -
                                  </Button>
                                </Box>
                              </Grid>

                            </Grid>

                        );
                      },
                  )}

              </CardContent>
              <Divider/>
              <CardActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}>
                  Save details
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => handlePreview(12, values)}>
                  Preview
                </Button>
              </CardActions>
            </Card>
          </Box>
          {
            segmentInfos?.payload?.length > 0 &&
            <Box mt={3}>
              <Card
                  {...rest}
                  className={clsx(classes.root, className)}
              >
                <CardHeader
                    //subheader={'Total: '+segmentInfos?.length}
                    title="Segment Preview"
                />
                <Divider/>
                  <SegmentPreview data={segmentInfos} values={values} setData={setInfo} preview={preview} setPreview={setPreview}/>
              </Card>
            </Box>
          }
        </form>
        <SnackBar open={open} message={message} severity={severity} handleClose={handleClose}/>
      </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Form;
