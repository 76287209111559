import React from 'react';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import useStyles from './style';
import { useSelector } from 'react-redux';

const SelectIncomesYearDropdown = ({ optionsList, changeHandler }) => {
  const classes = useStyles();
  const { year } = useSelector((state) => state.incomes.currentDataDate);

  return (
    <div className={classes.year_select}>
      <DateRangeOutlinedIcon className={classes.icon} />
     
      <select
        className={classes.Select}
        name="select-year"
        onChange={(e) => changeHandler(parseInt(e.target.value, 10))}
      >
        {optionsList.map((option, index) => (
          <option value={option.value} key={option.value} selected={option.value === year}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectIncomesYearDropdown;
