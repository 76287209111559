import React from 'react';
import {makeStyles} from '@material-ui/styles';

import Toolbar from '../../components/Toolbar/Toolbar'
import {Card, CardContent} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    minHeight: '500px',
  },
  img: {
    width: '100%',
  },
}));

const DivisionStatistics = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Toolbar />
      <div className={classes.content}>
        <Card>
          <CardContent className={classes.content}>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default DivisionStatistics;
