import React, {useState, useCallback, useEffect} from 'react';
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
    Box,
    Button,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    makeStyles,
    Tooltip,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import bytesToSize from "../utils/bytesToSize";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Viewer } from '@react-pdf-viewer/core';
import { ScrollMode, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; 
import { Worker } from '@react-pdf-viewer/core'; 

const useStyles = makeStyles((theme) => ({
    root: {},
    dropZone: {
        border: `1px dashed ${theme.palette.divider}`,
        padding: theme.spacing(6),
        outline: "none",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "center",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            opacity: 0.5,
            cursor: "pointer",
        },
    },
    dragActive: {
        backgroundColor: theme.palette.action.active,
        opacity: 0.5,
    },
    image: {
        width: 130,
    },
    info: {
        marginTop: theme.spacing(1),
    },
    list: {
        maxHeight: 320,
    },
    actions: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "flex-end",
        "& > * + *": {
            marginLeft: theme.spacing(2),
        },
    },
    pdfContainer: {
        maxHeight: 620,
        maxWidth: 520,
        marginLeft: theme.spacing(30),
      },
}));

const FileDropZone = (props) => {
    const {className,setValues,values, thumbnail, onFileChange, onImageChange,isVideo, isImage, isPdf, isMultiple, multipleTypes,
        fileName, fileUrl, ...rest} = props;
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [pdfFile, setPdfFile]=useState(null);
    const [hidePdf, setHidePdf]=useState(false);
    const [selectedPdfFile, setSelectedPdfFile]=useState(null);
    const [viewPdf, setViewPdf]=useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        toolbarPlugin: {
            fullScreenPlugin: {
                onEnterFullScreen: (zoom) => {
                    zoom(SpecialZoomLevel.PageFit);
                    defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
                        ScrollMode.Wrapped
                    );
                },
                onExitFullScreen: (zoom) => {
                    zoom(SpecialZoomLevel.PageWidth);
                    defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
                        ScrollMode.Vertical
                    );
                },
            },
        },
    });

    useEffect(() => {
        handlePdfFilePreview();
      }, [pdfFile]);

      const handlePdfFileChange=(e)=>{
        let fileType = ['application/pdf'];
        setSelectedPdfFile(e);
        setPdfFile(selectedPdfFile);
          if(selectedPdfFile&&fileType.includes(selectedPdfFile.type)){
            let reader = new FileReader();
                reader.readAsDataURL(selectedPdfFile);
                reader.onloadend = (e) =>{
                  setPdfFile(e.target.result);
                }
          }
        setPdfFile(null);
      }
    const handlePdfFilePreview=(e)=>{
          if(pdfFile !== null){
            setViewPdf(pdfFile);
            setHidePdf(true);

          }
          else{
            setViewPdf(null);
          }
      }

   const handleDrop = (acceptedFiles) => {
       handleRemoveAll()
        setFiles(acceptedFiles);
        onFileChange(acceptedFiles);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDelete =  (file) => {
        const newFiles = [...file]
        newFiles.splice(file.indexOf(file), 1)
        setFiles(newFiles)
    }
    const handleDeleteUploaded = (file) => {
        const newValues = {...values}
       /* newValues.pdfs.splice(newValues.pdfs.indexOf(file), 1)
        setValues({
            ...values,
            pdfs: newValues.pdfs,
        });*/
    }
    const handleRemoveAll = () => {
        setSelectedPdfFile(null);
        setPdfFile(null);
        setViewPdf(null);
        setHidePdf(false);
        setFiles([]);
        if(!isImage){
            setValues({
                ...values,
                file: null,
            });
        }
        else{
            /*setValues({
                ...values,
                //thumbnail: null,
                imageFile: null,
            });*/
        }

    };
    const hidePdfPreview = (file) => {
        setPdfFile(null);
        setSelectedPdfFile(null);
        setViewPdf(null);
        setHidePdf(false);

      };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        accept: multipleTypes ? '.gif,.jpg,.png,.pdf,.jpeg, .svg,' : isImage ? '.jpeg,.jpg,.png,.gif , .svg' : isVideo ? 'video/*' : 'application/pdf',
        multiple: false,
    });
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <div
                className={clsx({
                    [classes.dropZone]: true,
                    [classes.dragActive]: isDragActive,
                })}
                {...getRootProps()}
            >

                <input {...getInputProps()} />
                <div>
                    <img
                        alt="Select file"
                        className={classes.image}
                        src="/images/undraw_add_file2_gvbb.svg"
                    />
                </div>
                <div>
                    <Typography gutterBottom variant="h3">
                        Select file
                    </Typography>
                    <Box mt={2}>
                        <Typography color="textPrimary" variant="body1">
                            Drop file here or click <Link underline="always">browse</Link>{" "}
                            thorough your machine
                        </Typography >
                    </Box>
                </div>
            </div>
           {fileName  && fileUrl &&
                (
                <>
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <List className={classes.list}>
                                <ListItem>
                                    <ListItemLink href={fileUrl}>
                                        <ListItemIcon>
                                            <FileCopyIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={fileName}
                                            style={{overflowWrap: 'break-word'}}
                                            primaryTypographyProps={{ variant: "h5" }}
                                        />
                                    </ListItemLink>
                                </ListItem>
                        </List>
                    </PerfectScrollbar>
                </>
            )}

            {((values.imageFile && values.imageFile !== null && !values.imageFile.path) || (thumbnail && thumbnail !== null) )&& isImage &&
            (

                <>
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <List className={classes.list}>
                            <ListItem>
                                <a href={values.imageFile ? values.imageFile: thumbnail? thumbnail : values.thumbnail}>
                                <img src={values.imageFile ? values.imageFile: thumbnail? thumbnail : values.thumbnail} width="30" height="30"/>
                                </a>
                                <div>
                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                    </Menu>
                                </div>
                            </ListItem>
                        </List>
                    </PerfectScrollbar>
                </>
            )
            }

            {files.length > 0 && (
                <>
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <List className={classes.list}>
                            {files.map((file, i) => (
                                <ListItem divider={i < files.length - 1} key={i}>
                                    <ListItemIcon>
                                        <FileCopyIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={file.name}
                                        style={{overflowWrap: 'break-word'}}
                                        primaryTypographyProps={{ variant: "h5" }}
                                        secondary={bytesToSize(file.size)}
                                    />
                                {isPdf &&
                                   <div> 
                                      {!hidePdf && 
                                        <Tooltip
                                            enterDelay={300}
                                            title="Double Click to Preview">
                                            <Button  size="xs"  onClick={() => handlePdfFileChange(file)}>
                                                <PictureAsPdfIcon />
                                            </Button>
                                        </Tooltip>}
                                        {hidePdf && 
                                        <Tooltip
                                            enterDelay={300}
                                            title="Hide preview">
                                            <Button  size="xs"  onClick={() => hidePdfPreview(file)}>
                                                <VisibilityOffIcon/>
                                            </Button>
                                        </Tooltip>}
                                    </div>}
                                   {/* <div>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={open}
                                            onClose={handleClose}
                                            PaperProps={{
                                                style: {
                                                    width: '20ch',
                                                },
                                            }}
                                        >
                                            <MenuItem onClick={handleDelete}>
                                                Delete
                                            </MenuItem>
                                        </Menu>
                                    </div>*/}
                                </ListItem>
                            ))}
                        </List>
                    </PerfectScrollbar>
                </>
            )}
            <div className={classes.actions}>
                <Button color="secondary" size="small" variant="contained" onClick={handleRemoveAll} size="small">
                    Remove all
                </Button>
               {/* <Button color="secondary" size="small" variant="contained" onClick={()=> onFileChange(files)}>
                    Upload file
                </Button>*/}
            </div>
            <div className={classes.pdfContainer}>
                    {/* show pdf conditionally (if we have one)  */}
                    {viewPdf &&
                      <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <div  style={{ height: "620px" }}>
                           <Viewer fileUrl={viewPdf} plugins={[defaultLayoutPluginInstance]} />
                        </div>
                  </Worker></>}
           </div>
        </div>
    );
};

FileDropZone.propTypes = {
    className: PropTypes.string,
    setValues: PropTypes.func,
    values: PropTypes.object,
    onFileChange: PropTypes.func,
};

export default FileDropZone;
