import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id',label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'points', name: 'points', label: 'Points', width: '10%', type: 'pointsType', color: 'primary', show: true, sortable: true,},
  {accessor: 'from_user', label: 'From User', width: '20%', show: true, type: 'concatString',},
  {accessor: 'to_user', label: 'To User', width: '20%', show: true, type: 'concatString',},
  {accessor: 'created_at', name: 'createdAt', type: 'date', label: 'Created At', width: '15%', show: true, sortable: true,},
  {accessor: 'updated_at', name: 'updatedAt', type: 'date', label: 'Updated At', width: '15%', show: true, sortable: true,},
  {accessor: 'admin.username', label: 'Admin', width: '20%', show: true, type: 'fieldValue',},

];
const fieldSearchable = [
  'id',
];
const fieldFilterable = [
  {name: 'fromUser.id', label: 'From user', type: 'userAutocomplete'},
  {name: 'toUser.id', label: 'To user', type: 'userAutocomplete'},
  {name: 'admin.id', label: 'Admin', type: 'adminAutocomplete'},
];
const PointTransactionList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`point-transactions`}
          //exportUrl={`affiliations/export`}
          pageLink ={'/users/point-transfers'}
          searchMessage={'Search (ID)'}
          baseUrl={`point-transactions`}
          removeAdd={true}
          noShow={true}
          noEdit = {true}
          noAction={true}
          noExport={true}
      />
  );
};

export default PointTransactionList;
